import { useEffect, useState } from "react";

const truncateRegex = /^([a-zA-Z0-9]{6})[a-zA-Z0-9]+([a-zA-Z0-9]{5})$/;

export const truncateAddress = (address) => {
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const useWallet = () => {
  const [connected, setConnected] = useState(false);

  const [unisat, setUnisat] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [publicKey, setPublicKey] = useState("");
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState({
    confirmed: 0,
    unconfirmed: 0,
    total: 0,
  });

  useEffect(() => {
    const unisat = window.unisat;
    if (unisat) setUnisat(unisat);
  }, []);

  const getBasicInfo = async () => {};

  const handleAccountsChanged = (_accounts) => {
    console.log("accounts changed", _accounts);

    if (_accounts.length > 0) {
      setAccounts(_accounts);
      setConnected(true);
      setAddress(_accounts[0]);
      getBasicInfo();
    } else {
      setConnected(false);
    }
  };

  const connect = async () => {
    if (!unisat) return;

    try {
      const result = await unisat.requestAccounts();
      handleAccountsChanged(result);
    } catch (error) {
      console.error(error);
    }
  };

  const disconnect = async () => {
    if (!unisat) return;

    try {
      await unisat.disconnect();
      setConnected(false);
      setAddress(undefined);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    connected,
    address,
    connect,
    disconnect,
  };
};

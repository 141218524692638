import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import TopMiners from '../TopMiners';
import { userService } from '../../services/userService';
import { Coins, RefreshCcw, AlertTriangle, Check } from 'lucide-react';
import ReferralModal from '../ReferralModal';

const MERCHANT_SOUNDS = [
  '/sounds/merchant/merchant1.mp3',
  '/sounds/merchant/merchant2.mp3',
  '/sounds/merchant/merchant3.mp3',
  '/sounds/merchant/merchant4.mp3',
  '/sounds/merchant/merchant5.mp3',
];

const SpinningNotification = () => (
  <div className="absolute inset-0 z-20 flex items-center justify-center bg-black/75 backdrop-blur-sm">
    <div className="bg-gray-900 border border-gray-800 rounded-xl p-8 max-w-sm w-full mx-4">
      <div className="text-center">
        <div className="relative w-16 h-16 mx-auto mb-4">
          <div className="absolute inset-0 bg-yellow-500/20 rounded-full animate-ping" />
          <div className="relative flex items-center justify-center w-full h-full bg-yellow-500/10 rounded-full">
            <RefreshCcw className="w-8 h-8 text-yellow-500 animate-spin" />
          </div>
        </div>
        <h3 className="text-xl font-bold text-white mb-2">Multiplying Your Points</h3>
        <p className="text-gray-400">Please wait while we process your bet...</p>
      </div>
    </div>
  </div>
);

const ResultNotification = ({ won, amount }) => (
  <div className={`absolute inset-0 z-20 flex items-center justify-center ${
    won ? 'bg-green-500/20' : 'bg-red-500/20'
  } backdrop-blur-sm`}>
    <div className="bg-gray-900 border border-gray-800 rounded-xl p-8 max-w-sm w-full mx-4">
      <div className="text-center">
        {won ? (
          <Check className="w-16 h-16 text-green-500 mx-auto mb-4" />
        ) : (
          <AlertTriangle className="w-16 h-16 text-red-500 mx-auto mb-4" />
        )}
        <h3 className="text-2xl font-bold text-white mb-2">
          {won ? 'You Won!' : 'You Lost!'}
        </h3>
        <p className="text-lg text-gray-200">
          {won ? `+${amount.toLocaleString()} Points` : `-${amount.toLocaleString()} Points`}
        </p>
      </div>
    </div>
  </div>
);

const Merchant = () => {
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState(null);
  const [points, setPoints] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [dailyFlips, setDailyFlips] = useState(0);
  const [result, setResult] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [betHistory, setBetHistory] = useState([]);
  const [showReferralModal, setShowReferralModal] = useState(!localStorage.getItem('nftMinerId'));

  const bgVideoRef = useRef(null);
  const audioRefs = useRef(MERCHANT_SOUNDS.map(() => new Audio()));

  const handleJoinWithReferral = async (referralCode) => {
    try {
      const ip = await userService.getIpAddress();
      const newUser = await userService.createUser(ip, referralCode);
      if (newUser) {
        setUserId(newUser.id);
        setUserData(newUser);
        localStorage.setItem('nftMinerId', newUser.id);
        setShowReferralModal(false);
      }
    } catch (error) {
      console.error('Error joining with referral:', error);
    }
  };

  const handleSkipReferral = async () => {
    try {
      const ip = await userService.getIpAddress();
      const newUser = await userService.createUser(ip);
      if (newUser) {
        setUserId(newUser.id);
        setUserData(newUser);
        localStorage.setItem('nftMinerId', newUser.id);
        setShowReferralModal(false);
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  useEffect(() => {
    const loadUser = async () => {
      try {
        const storedId = localStorage.getItem('nftMinerId');
        if (storedId) {
          const user = await userService.getUserById(storedId);
          setUserId(storedId);
          setUserData(user);
          const merchantStatus = await userService.getMerchantStatus(storedId);
          setPoints(merchantStatus.points);
          setDailyFlips(merchantStatus.dailyFlips);
          setBetHistory(merchantStatus.merchantHistory || []);
        }
      } catch (error) {
        console.error('Error loading merchant status:', error);
      }
    };

    loadUser();

    if (bgVideoRef.current) {
      bgVideoRef.current.play().catch(console.error);
    }

    MERCHANT_SOUNDS.forEach((sound, index) => {
      audioRefs.current[index].src = sound;
      audioRefs.current[index].load();
    });

    return () => {
      audioRefs.current.forEach(audio => {
        audio.pause();
        audio.currentTime = 0;
      });
      if (bgVideoRef.current) {
        bgVideoRef.current.pause();
      }
    };
  }, []);

  const handleBetChange = (e) => {
    const value = Math.min(points, Math.max(0, parseInt(e.target.value) || 0));
    setBetAmount(value);
  };

  const handleMaxBet = () => {
    setBetAmount(points);
  };

  const handleHalfBet = () => {
    setBetAmount(Math.floor(points / 2));
  };

  const calculateWinChance = () => {
    return dailyFlips === 0 ? 50 : 30;
  };

  const playRandomMerchantSound = () => {
    try {
      audioRefs.current.forEach(audio => {
        audio.pause();
        audio.currentTime = 0;
      });

      const randomIndex = Math.floor(Math.random() * MERCHANT_SOUNDS.length);
      const audio = audioRefs.current[randomIndex];
      audio.volume = 0.7;
      
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.error('Error playing merchant sound:', error);
        });
      }
    } catch (error) {
      console.error('Error playing merchant sound:', error);
    }
  };

  const spin = async () => {
    if (isSpinning || betAmount <= 0 || dailyFlips >= 2) return;

    setIsSpinning(true);

    const winChance = calculateWinChance();
    const isWin = Math.random() * 100 < winChance;

    try {
      playRandomMerchantSound();
      
      const updatedUser = await userService.submitMerchantBet(userId, betAmount, isWin);
      
      await new Promise(resolve => setTimeout(resolve, 3000));

      setResult(isWin);
      setShowNotification(true);
      setPoints(updatedUser.points);
      setDailyFlips(updatedUser.dailyFlips);
      setBetHistory(updatedUser.merchantHistory || []);

      setTimeout(() => {
        setIsSpinning(false);
        setShowNotification(false);
        setResult(null);
      }, 2000);

      setTimeout(() => {
        audioRefs.current.forEach(audio => {
          audio.pause();
          audio.currentTime = 0;
        });
      }, 6000);
    } catch (error) {
      console.error('Error processing bet:', error);
      alert(error.message || 'Failed to process bet');
      setIsSpinning(false);
      
      audioRefs.current.forEach(audio => {
        audio.pause();
        audio.currentTime = 0;
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 relative overflow-hidden">
      {showReferralModal && (
        <ReferralModal
          onJoin={handleJoinWithReferral}
          onSkip={handleSkipReferral}
        />
      )}

      <div className="fixed inset-0 z-0">
        <video
          ref={bgVideoRef}
          className="absolute top-0 left-0 w-full h-full object-cover opacity-30"
          src="/videos/background.mp4"
          loop
          muted
          playsInline
          style={{ filter: 'blur(8px) brightness(0.4)' }}
        />
      </div>

      <div className="relative z-10">
        <Header userId={userId} userData={userData} />
        <TopMiners />

        <main className="container mx-auto px-4 pt-28 pb-12">
          <div className="max-w-2xl mx-auto">
            <div className="rounded-xl border border-gray-800 bg-gray-900/50 overflow-hidden">
              <div className="relative aspect-video bg-gray-800">
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent z-10" />
                <img
                  src="/images/merchant/x2.png"
                  alt="Multiply x2"
                  className="w-full h-full object-contain"
                />
                
                {isSpinning && <SpinningNotification />}
                {showNotification && (
                  <ResultNotification won={result} amount={betAmount} />
                )}
              </div>

              <div className="p-6">
                <div className="space-y-6">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Coins className="w-5 h-5 text-yellow-500" />
                      <span className="text-white font-bold">{points.toLocaleString()} Points</span>
                    </div>
                    <div className="text-sm text-gray-400">
                      Daily Flips: {dailyFlips}/2
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm text-gray-400">
                      Bet Amount
                    </label>
                    <div className="flex gap-2">
                      <input
                        type="number"
                        value={betAmount}
                        onChange={handleBetChange}
                        className="flex-1 bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-white"
                        placeholder="Enter amount"
                        min="0"
                        max={points}
                      />
                      <button
                        onClick={handleHalfBet}
                        className="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg hover:bg-gray-700 transition-colors"
                      >
                        1/2
                      </button>
                      <button
                        onClick={handleMaxBet}
                        className="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg hover:bg-gray-700 transition-colors"
                      >
                        Max
                      </button>
                    </div>
                  </div>

                  <div className="text-center text-sm text-gray-400">
                    Win Chance: {calculateWinChance()}%
                  </div>

                  <button
                    onClick={spin}
                    disabled={isSpinning || betAmount <= 0 || dailyFlips >= 2}
                    className={`w-full py-4 rounded-lg font-bold transition-all ${
                      isSpinning || betAmount <= 0 || dailyFlips >= 2
                        ? 'bg-gray-800 text-gray-500 cursor-not-allowed'
                        : 'bg-yellow-500 text-white hover:bg-yellow-600 hover:scale-[1.02]'
                    }`}
                  >
                    {isSpinning 
                      ? 'Multiplying...' 
                      : dailyFlips >= 2 
                      ? 'Daily Limit Reached'
                      : 'Multiply x2'}
                  </button>

                  {dailyFlips >= 2 && (
                    <p className="text-center text-sm text-gray-400">
                      You've reached your daily flip limit. Come back tomorrow!
                    </p>
                  )}
                </div>
              </div>

              {betHistory.length > 0 && (
                <div className="border-t border-gray-800 p-6">
                  <h3 className="text-lg font-semibold text-white mb-4">Recent Bets</h3>
                  <div className="space-y-3">
                    {betHistory.slice(0, 5).map((bet, index) => (
                      <div key={index} className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          {bet.won ? (
                            <Check className="w-4 h-4 text-green-500" />
                          ) : (
                            <AlertTriangle className="w-4 h-4 text-red-500" />
                          )}
                          <span className="text-sm text-gray-400">
                            {new Date(bet.date).toLocaleString()}
                          </span>
                        </div>
                        <span className={`text-sm font-semibold ${
                          bet.won ? 'text-green-500' : 'text-red-500'
                        }`}>
                          {bet.won ? '+' : '-'}{bet.betAmount.toLocaleString()} Points
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Merchant;
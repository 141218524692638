// src/components/SwitchAccountForm/index.js
import React, { useState } from 'react';
import { Key, ArrowRight, X } from 'lucide-react';

const SwitchAccountForm = ({ onSubmit }) => {
  const [inputId, setInputId] = useState('');
  const [secretCode, setSecretCode] = useState('');
  const [showSecretInput, setShowSecretInput] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputId.length === 15) {
      if (showSecretInput && secretCode) {
        await onSubmit(inputId, secretCode);
        setInputId('');
        setSecretCode('');
        setShowSecretInput(false);
      } else {
        setShowSecretInput(true);
      }
    }
  };

  if (!isVisible) return null;

  return (
    <div className="mb-8 rounded-xl border border-gray-800 bg-gray-900/50 p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-white flex items-center gap-2">
          <Key className="w-5 h-5 text-yellow-500" />
          Switch Account
        </h2>
        <button 
          onClick={() => setIsVisible(false)}
          className="p-1 hover:bg-gray-800 rounded-md transition-colors"
        >
          <X className="w-5 h-5 text-gray-400" />
        </button>
      </div>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <div className="relative">
            <input
              type="text"
              value={inputId}
              onChange={(e) => setInputId(e.target.value.toUpperCase())}
              placeholder="Enter Account ID"
              className="w-full bg-gray-800/50 border border-gray-700 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:border-yellow-500/50 font-mono"
              maxLength={15}
            />
            {!showSecretInput && (
              <button
                type="submit"
                disabled={inputId.length !== 15}
                className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-md bg-yellow-500/10 text-yellow-500 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-yellow-500/20 transition-colors"
              >
                <ArrowRight className="w-4 h-4" />
              </button>
            )}
          </div>
          {inputId.length > 0 && inputId.length !== 15 && (
            <p className="mt-2 text-sm text-gray-400">ID must be exactly 15 characters</p>
          )}
        </div>

        {showSecretInput && (
          <div className="space-y-4">
            <div className="relative">
              <input
                type="text"
                value={secretCode}
                onChange={(e) => setSecretCode(e.target.value)}
                placeholder="Enter Secret Code"
                className="w-full bg-gray-800/50 border border-gray-700 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:border-yellow-500/50 font-mono"
              />
            </div>
            <button
              type="submit"
              disabled={!secretCode}
              className="w-full py-3 rounded-lg font-semibold transition-all bg-yellow-500 text-white hover:bg-yellow-600 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-yellow-500"
            >
              Switch Account
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default SwitchAccountForm;
// src/components/BitcoinAddressForm/index.js
import React, { useState } from 'react';
import { Key, ArrowRight, X } from 'lucide-react';

const BitcoinAddressForm = ({ onSubmit, initialBtcAddress }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [btcAddress, setBtcAddress] = useState(initialBtcAddress || '');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!btcAddress || btcAddress.trim().length < 10) {
      setError('Please enter a valid Bitcoin address');
      return;
    }

    try {
      await onSubmit(btcAddress.trim());
      // Form başarıyla gönderildikten sonra başarı mesajı gösterebilirsiniz
    } catch (error) {
      setError(error.message || 'Failed to update Bitcoin address');
    }
  };

  if (!isVisible) return null;

  return (
    <div className="mb-8 rounded-xl border border-gray-800 bg-gray-900/50 p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-white flex items-center gap-2">
          <Key className="w-5 h-5 text-yellow-500" />
          Add Fractal Bitcoin Address
        </h2>
        <button 
          onClick={() => setIsVisible(false)}
          className="p-1 hover:bg-gray-800 rounded-md transition-colors"
        >
          <X className="w-5 h-5 text-gray-400" />
        </button>
      </div>
      
      <div className="space-y-2">
        <p className="text-sm text-gray-400">Add your Bitcoin address to receive future airdrops</p>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <div className="relative">
              <input
                type="text"
                value={btcAddress}
                onChange={(e) => setBtcAddress(e.target.value)}
                placeholder="Enter Bitcoin Address"
                className="w-full bg-gray-800/50 border border-gray-700 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:outline-none focus:border-yellow-500/50 font-mono"
              />
              <button
                type="submit"
                disabled={!btcAddress.trim()}
                className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-md bg-yellow-500/10 text-yellow-500 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-yellow-500/20 transition-colors"
              >
                <ArrowRight className="w-4 h-4" />
              </button>
            </div>
            {error && (
              <p className="mt-2 text-sm text-red-500">{error}</p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default BitcoinAddressForm;
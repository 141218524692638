import React, { useState } from 'react';
import { AlertTriangle, Bell, X, ChevronDown, ChevronUp, Wallet } from 'lucide-react';

export const MultiAccountWarning = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="fixed left-0 top-[calc(33vh+320px)] z-50">
      <div className="flex">
        <div 
          className={`${isExpanded ? 'w-72' : 'w-12'} bg-red-500/10 backdrop-blur-lg border-y border-r border-red-500/20 rounded-r-xl overflow-hidden transition-all duration-300`}
        >
          <button 
            onClick={() => setIsExpanded(!isExpanded)}
            className="w-full p-3 flex items-center gap-2 hover:bg-red-500/10 transition-colors"
          >
            <AlertTriangle className="w-5 h-5 text-red-500 shrink-0" />
            {isExpanded && (
              <span className="text-red-500 font-medium text-sm">Multi-Account Warning</span>
            )}
          </button>

          {isExpanded && (
            <div className="p-4 text-sm text-gray-300 space-y-2">
              <p>
                If you have created more than 4 accounts, your accounts may be at risk of being reset.
              </p>
              <p className="text-red-400">
                To prevent multi-accounting, please play with a maximum of 4 accounts.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const VersionNotification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div className="flex flex-col items-end space-y-2">
        {isOpen && (
          <div className="bg-gray-900/95 backdrop-blur-sm border border-gray-800 rounded-lg shadow-xl w-80 transform transition-all duration-300 scale-100 opacity-100">
            <div className="p-4">
              <div className="flex justify-between items-start mb-3">
                <div className="flex items-center gap-2">
                  <Bell className="w-5 h-5 text-yellow-500" />
                  <span className="text-sm font-semibold text-white">Beta 1.04.32</span>
                </div>
                <button 
                  onClick={() => setIsVisible(false)}
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
              
              <div className="space-y-3 text-sm">
                <p className="text-gray-300">
                There may be mistakes, we apologize in advance. We are constantly improving our system.
                </p>
                <div className="p-3 bg-yellow-500/10 border border-yellow-500/20 rounded-lg">
                  <div className="flex items-start gap-2">
                    <Wallet className="w-4 h-4 text-yellow-500 mt-0.5" />
                    <p className="text-yellow-500">
                    When it reaches the full version, you will be able to log in with Fractal Wallet and instantly see the points as tokens in your wallet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-yellow-500/10 hover:bg-yellow-500/20 border border-yellow-500/20 rounded-lg px-4 py-2 text-yellow-500 flex items-center gap-2 transition-all duration-300 animate-pulse hover:animate-none"
        >
          <Bell className="w-5 h-5" />
          <span className="font-medium">Beta Version</span>
          {isOpen ? (
            <ChevronDown className="w-4 h-4" />
          ) : (
            <ChevronUp className="w-4 h-4" />
          )}
        </button>
      </div>
    </div>
  );
};
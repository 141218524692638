import React, { useState } from 'react';
import { Gift, Users } from 'lucide-react';

const ReferralModal = ({ onJoin, onSkip }) => {
  const [referralCode, setReferralCode] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!referralCode) {
      setError('Please enter a referral code');
      return;
    }
    onJoin(referralCode);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center px-4">
      <div className="absolute inset-0 bg-black/75 backdrop-blur-sm" />
      <div className="relative bg-gray-900 rounded-xl border border-gray-800 p-8 max-w-md w-full mx-auto">
        <div className="text-center space-y-6">
          <div className="w-16 h-16 mx-auto rounded-full bg-yellow-500/10 flex items-center justify-center">
            <Gift className="w-8 h-8 text-yellow-500" />
          </div>

          <div className="space-y-2">
            <h3 className="text-2xl font-bold text-white">Welcome to Fractal Miners</h3>
            <p className="text-gray-400">Join with a referral code to earn bonus points!</p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="text"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value.toUpperCase())}
                placeholder="Enter Referral Code"
                className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-3 text-white placeholder-gray-500"
              />
              {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
            </div>

            <button
              type="submit"
              className="w-full py-3 rounded-lg bg-yellow-500 text-white font-semibold hover:bg-yellow-600 transition-colors"
            >
              Join with Referral
            </button>

            <button
              type="button"
              onClick={onSkip}
              className="w-full py-3 rounded-lg bg-gray-800 text-gray-400 font-semibold hover:bg-gray-700 transition-colors"
            >
              Skip (No Referral Code)
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReferralModal;
import React, { useState, useEffect } from 'react';
import Header from '../Header';
import TopMiners from '../TopMiners';
import { Timer, Gift, Info, Users } from 'lucide-react';
import { userService } from '../../services/userService';
import ReferralModal from '../ReferralModal';

const AirdropCard = ({ title, description }) => (
  <div className="relative h-[420px] overflow-hidden rounded-xl border border-gray-800">
    <div className="absolute inset-0 bg-gray-900/90">
      <div className="absolute inset-0 flex items-center justify-center opacity-20">
        <img 
          src="/images/icons/fractal.png"
          alt="Fractal"
          className="w-48 h-48 object-contain"
        />
      </div>
    </div>

    <div className="absolute top-4 right-4">
      <div className="flex items-center gap-2 bg-yellow-500/20 text-yellow-500 px-3 py-1 rounded-full text-sm">
        <Timer className="w-4 h-4" />
        <span>Coming Soon</span>
      </div>
    </div>
    
    <div className="absolute inset-x-0 bottom-0 p-6">
      <div className="space-y-4">
        <div className="flex items-center gap-3">
          <Gift className="w-6 h-6 text-yellow-500" />
          <h3 className="text-lg font-bold text-white">{title}</h3>
        </div>

        <p className="text-gray-300">{description}</p>

        <div className="flex items-center justify-between pt-4 border-t border-gray-700/50">
          <span className="text-sm text-yellow-500">TBA</span>
          <button className="flex items-center gap-2 px-4 py-2 rounded-lg bg-gray-800/50 text-gray-300 hover:bg-gray-700/50 transition-colors">
            <Info className="w-4 h-4" />
            <span>Details</span>
          </button>
        </div>
      </div>
    </div>
  </div>
);

const Airdrops = () => {
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState(null);
  const [showReferralModal, setShowReferralModal] = useState(!localStorage.getItem('nftMinerId'));

  const handleJoinWithReferral = async (referralCode) => {
    try {
      const ip = await userService.getIpAddress();
      const newUser = await userService.createUser(ip, referralCode);
      if (newUser) {
        setUserId(newUser.id);
        setUserData(newUser);
        localStorage.setItem('nftMinerId', newUser.id);
        setShowReferralModal(false);
      }
    } catch (error) {
      console.error('Error joining with referral:', error);
    }
  };

  const handleSkipReferral = async () => {
    try {
      const ip = await userService.getIpAddress();
      const newUser = await userService.createUser(ip);
      if (newUser) {
        setUserId(newUser.id);
        setUserData(newUser);
        localStorage.setItem('nftMinerId', newUser.id);
        setShowReferralModal(false);
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };
  
  useEffect(() => {
    const loadUser = async () => {
      try {
        const storedId = localStorage.getItem('nftMinerId');
        console.log('Stored ID:', storedId);
        
        if (storedId) {
          const user = await userService.getUserById(storedId);
          if (user) {
            console.log('User loaded:', user);
            setUserId(user.id);
            setUserData(user);
          } else {
            console.log('No user found with stored ID, creating new user');
            const ip = await userService.getIpAddress();
            const newUser = await userService.createUser(ip);
            if (newUser) {
              console.log('New user created:', newUser);
              setUserId(newUser.id);
              setUserData(newUser);
              localStorage.setItem('nftMinerId', newUser.id);
            }
          }
        } else {
          console.log('No stored ID found, showing referral modal');
          setShowReferralModal(true);
        }
      } catch (error) {
        console.error('Error loading/creating user:', error);
      }
    };

    loadUser();
  }, []);

  const airdrops = [
    {
      title: "Genesis NFT Airdrop",
      description: "Early supporters will receive exclusive Genesis NFTs with unique utilities and benefits."
    },
    {
      title: "Token Airdrop",
      description: "Active miners will be eligible for the upcoming token airdrop based on their mining activity."
    },
    {
      title: "Mystery Airdrop #1",
      description: "Details will be announced soon. Stay tuned and keep mining!"
    },
    {
      title: "Mystery Airdrop #2",
      description: "Something big is coming. More information will be revealed soon."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 relative overflow-hidden">
      {showReferralModal && (
        <ReferralModal
          onJoin={handleJoinWithReferral}
          onSkip={handleSkipReferral}
        />
      )}

      <div className="fixed inset-0 bg-gradient-to-b from-gray-900 to-black" />

      <div className="relative z-10">
        <Header userId={userId} userData={userData} />
        <TopMiners />
        
        <main className="container mx-auto px-4 pt-28 pb-12">
          <div className="mb-12 text-center">
            <h1 className="text-3xl font-bold text-white mb-4">Upcoming Airdrops</h1>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Discover and participate in upcoming airdrops. Keep mining to increase your eligibility!
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
            <div className="p-6 rounded-xl border border-gray-800 bg-gray-900/50">
              <div className="flex flex-col items-center text-center">
                <h3 className="text-2xl font-bold text-yellow-500 mb-2">4</h3>
                <p className="text-gray-400">Planned Airdrops</p>
              </div>
            </div>
            <div className="p-6 rounded-xl border border-gray-800 bg-gray-900/50">
              <div className="flex flex-col items-center text-center">
                <h3 className="text-2xl font-bold text-yellow-500 mb-2">TBA</h3>
                <p className="text-gray-400">Next Airdrop</p>
              </div>
            </div>
            <div className="p-6 rounded-xl border border-gray-800 bg-gray-900/50">
              <div className="flex flex-col items-center text-center">
                <h3 className="text-2xl font-bold text-yellow-500 mb-2">3,333</h3>
                <p className="text-gray-400">Total NFTs</p>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {airdrops.map((airdrop, index) => (
              <AirdropCard key={index} {...airdrop} />
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Airdrops;
// src/components/Header/index.js
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  CircleDollarSign,
  Gift,
  Copy,
  Check,
  Key,
  Coins,
  Package,
  Users,
  RefreshCw,
  Rocket,
} from "lucide-react";
import { userService } from "../../services/userService";

const Header = ({ userId, userData, setUserId, setUserData }) => {
  const [copied, setCopied] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const [showReferralInfo, setShowReferralInfo] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const location = useLocation();

  const handleRefresh = async () => {
    const confirmReset = window.confirm(
      "This will generate a new user ID. Are you sure you want to continue? \n\nNote: Your current progress will not be saved."
    );

    if (confirmReset) {
      try {
        setRefreshing(true);
        // Mevcut ID'yi localStorage'dan sil
        localStorage.removeItem("nftMinerId");

        // Yeni kullanıcı oluştur
        const ip = await userService.getIpAddress();
        const newUser = await userService.createUser(ip);

        if (newUser) {
          // Yeni kullanıcı bilgilerini güncelle
          setUserId(newUser.id);
          setUserData(newUser);
          localStorage.setItem("nftMinerId", newUser.id);

          alert("New account created successfully! Your new ID: " + newUser.id);
          window.location.reload(); // Sayfayı yenile
        }
      } catch (error) {
        console.error("Error refreshing user:", error);
        alert("Failed to create new account. Please try again.");
      } finally {
        setRefreshing(false);
      }
    }
  };

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const getSecretCode = () => {
    const firstFive = userId.slice(0, 5).split("").reverse().join("");
    return btoa(firstFive + userId.slice(-3));
  };

  return (
    <header className="fixed top-0 w-full z-50 bg-gray-900/80 backdrop-blur-lg border-b border-gray-800">
      <div className="max-w-7xl mx-auto px-4">
        <div className="h-12 flex items-center justify-between border-b border-gray-800">
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <img
                src="/images/icons/fractal.png"
                alt="Fractal"
                className="w-6 h-6"
              />
              <span className="font-medium text-white">Fractal</span>
            </div>
          </div>
          <div className="flex items-center gap-4">
            {userId ? (
              <div className="flex items-center gap-2">
                <img
                  src="/images/icons/fractals.png"
                  alt="User"
                  className="w-5 h-5"
                />
                <div className="relative group">
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-mono text-gray-400">
                      ID: {userId}
                    </span>
                    <button
                      onClick={() => handleCopy(userId)}
                      className="p-1 hover:bg-gray-800 rounded-md transition-colors"
                    >
                      {copied ? (
                        <Check className="w-4 h-4 text-green-500" />
                      ) : (
                        <Copy className="w-4 h-4 text-gray-400" />
                      )}
                    </button>
                    <button
                      onClick={() => setShowSecret(!showSecret)}
                      className="p-1 hover:bg-gray-800 rounded-md transition-colors"
                    >
                      <Key className="w-4 h-4 text-yellow-500" />
                    </button>
                    <button
                      onClick={() => setShowReferralInfo(!showReferralInfo)}
                      className="flex items-center gap-1 text-gray-400 hover:text-white transition-colors"
                    >
                      <Users className="w-4 h-4" />
                      <span className="text-sm">
                        {userData?.referralCount || 0} Referrals
                      </span>
                    </button>
                  </div>

                  {showSecret && (
                    <div className="absolute top-full right-0 mt-2 p-3 bg-gray-800 border border-gray-700 rounded-md shadow-xl">
                      <div className="text-sm font-mono">
                        <span className="text-gray-400">Secret Code: </span>
                        <span className="text-yellow-500">
                          {getSecretCode()}
                        </span>
                      </div>
                    </div>
                  )}

                  {showReferralInfo && (
                    <div className="absolute top-full right-0 mt-2 p-4 bg-gray-800 border border-gray-700 rounded-md shadow-xl">
                      <div className="space-y-3">
                        <div>
                          <h4 className="text-sm font-medium text-white mb-1">
                            Your Referral Codes
                          </h4>
                          {userData?.referralCodes?.map(
                            (referralItem, index) => (
                              <div
                                key={index}
                                className="flex items-center gap-2"
                              >
                                <code
                                  className={`${
                                    typeof referralItem === "object" &&
                                    referralItem.used
                                      ? "text-gray-500"
                                      : "text-yellow-500"
                                  }`}
                                >
                                  {typeof referralItem === "string"
                                    ? referralItem
                                    : referralItem.code}
                                </code>
                                {typeof referralItem === "object" &&
                                referralItem.used ? (
                                  <div className="flex items-center gap-1 text-green-500 text-sm">
                                    <Check className="w-4 h-4" />
                                    <span>Used</span>
                                  </div>
                                ) : (
                                  <button
                                    onClick={() =>
                                      handleCopy(
                                        typeof referralItem === "string"
                                          ? referralItem
                                          : referralItem.code
                                      )
                                    }
                                    className="p-1 hover:bg-gray-700 rounded-md transition-colors"
                                  >
                                    <Copy className="w-4 h-4 text-gray-400" />
                                  </button>
                                )}
                              </div>
                            )
                          )}
                        </div>
                        {userData?.referredBy && (
                          <div>
                            <h4 className="text-sm font-medium text-white mb-1">
                              Referred By
                            </h4>
                            <code className="text-gray-400">
                              {userData.referredBy}
                            </code>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            <button
              onClick={handleRefresh}
              disabled={refreshing}
              className={`p-2 rounded-lg transition-all ${
                refreshing
                  ? "bg-gray-800 text-gray-500 cursor-not-allowed"
                  : "bg-yellow-500/10 text-yellow-500 hover:bg-yellow-500/20"
              }`}
              title="Reset Account"
            >
              <RefreshCw
                className={`w-4 h-4 ${refreshing ? "animate-spin" : ""}`}
              />
            </button>
          </div>
        </div>

        <nav className="h-14 flex items-center">
          <div className="flex gap-4">
            <Link
              to="/miners"
              className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                location.pathname === "/miners"
                  ? "bg-yellow-500/10 text-yellow-500"
                  : "hover:bg-gray-800/50 text-gray-400"
              }`}
            >
              <CircleDollarSign className="w-4 h-4" />
              <span>Miners</span>
            </Link>
            <Link
              to="/airdrops"
              className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                location.pathname === "/airdrops"
                  ? "bg-yellow-500/10 text-yellow-500"
                  : "hover:bg-gray-800/50 text-gray-400"
              }`}
            >
              <Gift className="w-4 h-4" />
              <span>Airdrops</span>
            </Link>
            <Link
              to="/merchant"
              className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                location.pathname === "/merchant"
                  ? "bg-yellow-500/10 text-yellow-500"
                  : "hover:bg-gray-800/50 text-gray-400"
              }`}
            >
              <Coins className="w-4 h-4" />
              <span>Merchant</span>
            </Link>
            <Link
              to="/trade"
              className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                location.pathname === "/trade"
                  ? "bg-yellow-500/10 text-yellow-500"
                  : "hover:bg-gray-800/50 text-gray-400"
              }`}
            >
              <Package className="w-4 h-4" />
              <span>Trade</span>
            </Link>
            <Link
              to="/journey"
              className={`flex items-center gap-2 px-4 py-2 rounded-lg ${
                location.pathname === "/journey"
                  ? "bg-yellow-500/10 text-yellow-500"
                  : "hover:bg-gray-800/50 text-gray-400"
              }`}
            >
              <Rocket className="w-4 h-4" />
              <span>Journey</span>
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;

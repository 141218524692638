import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import TopMiners from '../TopMiners';
import { Package, Timer, Axe, Star, ChevronDown, ChevronUp, Sparkles, Wallet, Lock } from 'lucide-react';
import { userService } from '../../services/userService';
import ReferralModal from '../ReferralModal';

const PICKAXE_TIERS = {
  UNCOMMON: {
    color: 'text-green-500',
    bgColor: 'bg-green-500/10',
    borderColor: 'border-green-500/20',
    dailyMines: 2
  },
  RARE: {
    color: 'text-blue-500',
    bgColor: 'bg-blue-500/10',
    borderColor: 'border-blue-500/20',
    dailyMines: 4
  },
  EPIC: {
    color: 'text-purple-500',
    bgColor: 'bg-purple-500/10',
    borderColor: 'border-purple-500/20',
    dailyMines: 6
  },
  LEGENDARY: {
    color: 'text-yellow-500',
    bgColor: 'bg-yellow-500/10',
    borderColor: 'border-yellow-500/20',
    dailyMines: 9
  }
};

const pickaxes = [
  {
    id: 1,
    name: "Uncommon Miner's Pickaxe",
    tier: 'UNCOMMON',
    description: "Increases daily mining limit to 2",
    chance: "55%"
  },
  {
    id: 2,
    name: "Rare Miner's Pickaxe",
    tier: 'RARE',
    description: "Increases daily mining limit to 4",
    chance: "25%"
  },
  {
    id: 3,
    name: "Epic Miner's Pickaxe",
    tier: 'EPIC',
    description: "Increases daily mining limit to 6",
    chance: "15%"
  },
  {
    id: 4,
    name: "Legendary Miner's Pickaxe",
    tier: 'LEGENDARY',
    description: "Increases daily mining limit to 9",
    chance: "5%"
  }
];

const NFTBox = ({ onOpen, isOpened, unlockedPickaxe }) => {
  const videoRef = useRef(null);
  const boxVideoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(console.error);
    }
    if (boxVideoRef.current) {
      boxVideoRef.current.play().catch(console.error);
    }
  }, []);

  return (
    <div className="relative rounded-xl border border-yellow-500/20 bg-gray-900/50 overflow-hidden">
      <video
        ref={videoRef}
        className="absolute inset-0 w-full h-full object-cover opacity-30"
        src="/videos/box1.mp4"
        loop
        muted
        playsInline
      />
      
      <div className="relative z-10 p-8">
        <div className="text-center space-y-6">
          <div className="mb-8">
            <img src="/images/icons/fractal.png" alt="Fractal" className="w-16 h-16 mx-auto" />
          </div>

          <div className="grid grid-cols-2 gap-4 mb-8">
            <div className="aspect-square rounded-lg border border-yellow-500/20 bg-gray-800/50 flex items-center justify-center relative overflow-hidden">
              <video
                ref={boxVideoRef}
                className="absolute inset-0 w-full h-full object-cover"
                src="/videos/box1.mp4"
                loop
                muted
                playsInline
              />
              <div className="relative z-10">
                <Package className="w-12 h-12 text-yellow-500" />
              </div>
            </div>
            <div className="aspect-square rounded-lg border border-gray-700/50 bg-gray-800/50 flex items-center justify-center relative">
              {isOpened && unlockedPickaxe ? (
                <div className={`w-full h-full flex items-center justify-center ${PICKAXE_TIERS[unlockedPickaxe.tier].bgColor}`}>
                  <Axe className={`w-12 h-12 ${PICKAXE_TIERS[unlockedPickaxe.tier].color}`} />
                </div>
              ) : (
                <>
                  <div className="absolute inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center">
                    <Lock className="w-8 h-8 text-gray-600" />
                  </div>
                  <span className="text-4xl text-gray-600">?</span>
                </>
              )}
            </div>
          </div>

          <div className="space-y-2">
            <h3 className="text-2xl font-bold text-white">Fractal Bitcoin Box</h3>
            <p className="text-gray-400">
              {isOpened 
                ? "This is a test box opening. Purchase an NFT during the mint to unlock real boxes!" 
                : "Connect your wallet and wait for the mint date to unlock boxes"}
            </p>
          </div>

          <div className="flex flex-col gap-3">
            <button
              className="w-full py-4 px-6 rounded-lg font-bold bg-yellow-500/20 text-yellow-500 hover:bg-yellow-500/30 transition-all flex items-center justify-center gap-2"
            >
              <Wallet className="w-5 h-5" />
              Connect Wallet
            </button>

            <button
              onClick={onOpen}
              disabled={isOpened}
              className={`w-full py-4 px-6 rounded-lg font-bold transition-all ${
                isOpened
                  ? 'bg-gray-800 text-gray-500 cursor-not-allowed'
                  : 'bg-yellow-500 text-white hover:bg-yellow-600 hover:scale-[1.02]'
              }`}
            >
              {isOpened ? 'Box Opened (Test Only)' : 'Open Test Box'}
            </button>
          </div>

          <p className="text-sm text-gray-400">
            Mint Price: 1 Fractal Bitcoin
          </p>

          <div className="mt-4 p-4 rounded-lg bg-yellow-500/10 border border-yellow-500/20">
            <p className="text-sm text-yellow-500">
              Mint Date: November 28, 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const Economics = () => (
  <div className="rounded-xl border border-gray-800 bg-gray-900/50 p-6 space-y-6">
    <h3 className="text-lg font-bold text-white flex items-center gap-2">
      <Star className="w-5 h-5 text-yellow-500" />
      NFT Economics
    </h3>

    <div className="space-y-4">
      <p className="text-gray-400">
        Purchase Fractal Bitcoin NFTs during the mint phase to unlock exclusive mining boxes. Each NFT grants:
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="p-4 rounded-lg bg-gray-800/50 border border-gray-700">
          <div className="text-center">
            <Package className="w-6 h-6 text-yellow-500 mx-auto mb-2" />
            <h4 className="font-semibold text-white mb-1">Box Access</h4>
            <p className="text-sm text-gray-400">Unlock mining boxes to enhance your mining capabilities</p>
          </div>
        </div>

        <div className="p-4 rounded-lg bg-gray-800/50 border border-gray-700">
          <div className="text-center">
            <Axe className="w-6 h-6 text-yellow-500 mx-auto mb-2" />
            <h4 className="font-semibold text-white mb-1">Enhanced Mining</h4>
            <p className="text-sm text-gray-400">Get better tools and increased daily mining limits</p>
          </div>
        </div>

        <div className="p-4 rounded-lg bg-gray-800/50 border border-gray-700">
          <div className="text-center">
            <Sparkles className="w-6 h-6 text-yellow-500 mx-auto mb-2" />
            <h4 className="font-semibold text-white mb-1">Early Access</h4>
            <p className="text-sm text-gray-400">Be among the first to mine with enhanced capabilities</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const PossibleRewards = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="rounded-xl border border-gray-800 bg-gray-900/50 overflow-hidden">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full p-6 flex items-center justify-between hover:bg-gray-800/50 transition-colors"
      >
        <h3 className="text-lg font-bold text-white flex items-center gap-2">
          <Package className="w-5 h-5 text-yellow-500" />
          Possible Rewards
        </h3>
        {isExpanded ? (
          <ChevronUp className="w-5 h-5 text-gray-400" />
        ) : (
          <ChevronDown className="w-5 h-5 text-gray-400" />
        )}
      </button>

      {isExpanded && (
        <div className="p-6 pt-0 space-y-4">
          {pickaxes.map((pickaxe) => (
            <div
              key={pickaxe.id}
              className={`p-4 rounded-lg ${PICKAXE_TIERS[pickaxe.tier].bgColor} border ${PICKAXE_TIERS[pickaxe.tier].borderColor}`}
            >
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <h4 className={`font-bold ${PICKAXE_TIERS[pickaxe.tier].color}`}>
                    {pickaxe.name}
                  </h4>
                  <p className="text-sm text-gray-400">{pickaxe.description}</p>
                </div>
                <div className="text-right">
                  <span className={`text-sm font-medium ${PICKAXE_TIERS[pickaxe.tier].color}`}>
                    {pickaxe.chance}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const OpeningAnimation = ({ pickaxe, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="absolute inset-0 bg-black/75 backdrop-blur-sm" onClick={onClose} />
    <div className="relative bg-gray-900 rounded-xl border border-gray-800 p-8 max-w-md w-full mx-4">
      <div className="text-center space-y-6">
        <div className={`w-20 h-20 mx-auto rounded-full ${PICKAXE_TIERS[pickaxe.tier].bgColor} flex items-center justify-center`}>
          <Axe className={`w-10 h-10 ${PICKAXE_TIERS[pickaxe.tier].color}`} />
        </div>

        <div className="space-y-2">
          <h3 className={`text-2xl font-bold ${PICKAXE_TIERS[pickaxe.tier].color}`}>
            {pickaxe.name}
          </h3>
          <p className="text-gray-400">{pickaxe.description}</p>
          <p className="text-sm text-yellow-500">This is a test opening. Purchase an NFT to unlock real mining tools!</p>
        </div>

        <button
          onClick={onClose}
          className="w-full py-3 rounded-lg bg-yellow-500 text-white font-semibold hover:bg-yellow-600 transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  </div>
);

const Trade = () => {
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState(null);
  const [isBoxOpened, setIsBoxOpened] = useState(false);
  const [openedPickaxe, setOpenedPickaxe] = useState(null);
  const [showReferralModal, setShowReferralModal] = useState(!localStorage.getItem('nftMinerId'));

  const handleJoinWithReferral = async (referralCode) => {
    try {
      const ip = await userService.getIpAddress();
      const newUser = await userService.createUser(ip, referralCode);
      if (newUser) {
        setUserId(newUser.id);
        setUserData(newUser);
        localStorage.setItem('nftMinerId', newUser.id);
        setShowReferralModal(false);
      }
    } catch (error) {
      console.error('Error joining with referral:', error);
    }
  };

  const handleSkipReferral = async () => {
    try {
      const ip = await userService.getIpAddress();
      const newUser = await userService.createUser(ip);
      if (newUser) {
        setUserId(newUser.id);
        setUserData(newUser);
        localStorage.setItem('nftMinerId', newUser.id);
        setShowReferralModal(false);
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  useEffect(() => {
    const loadUser = async () => {
      try {
        const storedId = localStorage.getItem('nftMinerId');
        if (storedId) {
          const user = await userService.getUserById(storedId);
          setUserId(storedId);
          setUserData(user);
        }
      } catch (error) {
        console.error('Error loading user:', error);
      }
    };
  
    loadUser();
  }, []);

  const handleOpenBox = () => {
    const randomNumber = Math.random() * 100;
    let selectedPickaxe;

    if (randomNumber < 55) {
      selectedPickaxe = pickaxes[0]; // Uncommon - 55%
    } else if (randomNumber < 80) {
      selectedPickaxe = pickaxes[1]; // Rare - 25%
    } else if (randomNumber < 95) {
      selectedPickaxe = pickaxes[2]; // Epic - 15%
    } else {
      selectedPickaxe = pickaxes[3]; // Legendary - 5%
    }

    setOpenedPickaxe(selectedPickaxe);
    setIsBoxOpened(true);
  };

  return (
    <div className="min-h-screen bg-gray-900 relative overflow-hidden">
      {showReferralModal && (
        <ReferralModal
          onJoin={handleJoinWithReferral}
          onSkip={handleSkipReferral}
        />
      )}
      
      <div className="fixed inset-0 bg-gradient-to-b from-gray-900 to-black" />

      <div className="relative z-10">
        <Header userId={userId} userData={userData} />
        <TopMiners />

        <main className="container mx-auto px-4 pt-28 pb-12">
          <div className="max-w-4xl mx-auto space-y-8">
            <NFTBox
              onOpen={handleOpenBox}
              isOpened={isBoxOpened}
              unlockedPickaxe={openedPickaxe}
            />
            <Economics />
            <PossibleRewards />
          </div>
        </main>
      </div>

      {openedPickaxe && (
        <OpeningAnimation
          pickaxe={openedPickaxe}
          onClose={() => {
            setOpenedPickaxe(null);
            setIsBoxOpened(true);
          }}
        />
      )}
    </div>
  );
};

export default Trade;
import React from 'react';
import { Hammer, ArrowUp } from 'lucide-react';

const PICKAXE_DETAILS = {
  1: { name: 'Bronze Pickaxe', boost: '+15%', color: 'text-amber-600' },
  2: { name: 'Silver Pickaxe', boost: '+30%', color: 'text-gray-400' },
  3: { name: 'Gold Pickaxe', boost: '+75%', color: 'text-yellow-500' },
  4: { name: 'Diamond Pickaxe', boost: '+125%', color: 'text-blue-400' },
  5: { name: 'Fractal Pickaxe', boost: '+200%', color: 'text-purple-500' },
  6: { name: 'Mystery Pickaxe', boost: '+400%', color: 'text-red-500' }
};

const PickaxeStatus = ({ unlockedCards = [] }) => {
  // En yüksek seviyeli pickaxe'i bul
  const highestPickaxeId = Math.max(...unlockedCards, 0);
  const activePickaxe = PICKAXE_DETAILS[highestPickaxeId];

  if (!activePickaxe) return null;

  return (
    <div className="p-4 rounded-lg bg-gray-800/50 border border-gray-700/50">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className={`p-2 rounded-lg ${activePickaxe.color} bg-opacity-10`}>
            <Hammer className={`w-5 h-5 ${activePickaxe.color}`} />
          </div>
          <div>
            <h3 className="font-medium text-white">{activePickaxe.name}</h3>
            <div className="flex items-center gap-1 text-sm">
              <ArrowUp className={`w-4 h-4 ${activePickaxe.color}`} />
              <span className={activePickaxe.color}>{activePickaxe.boost} Mining Boost</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickaxeStatus;
// src/components/Market/MinerTable.js
import React from 'react';

const MinerTable = ({ history, userId }) => {
  return (
    <div className="overflow-x-auto">
      <div className="rounded-xl border border-gray-800 bg-gray-900/50">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-800">
              <th className="text-left px-6 py-4 text-gray-400 font-normal">ID</th>
              <th className="text-center px-6 py-4 text-gray-400 font-normal">Date</th>
              <th className="text-center px-6 py-4 text-gray-400 font-normal">Time</th>
              <th className="text-right px-6 py-4 text-gray-400 font-normal">Points</th>
            </tr>
          </thead>
          <tbody>
            {history.map((record, index) => {
              const date = new Date(record.date);
              
              return (
                <tr key={index} className="border-b border-gray-800 hover:bg-gray-800/30">
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-3">
                      <img src="/images/icons/fractals.png" alt="User" className="w-8 h-8 rounded-full" />
                      <span className="text-white font-mono">{userId}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-center text-white">
                    {date.toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-center text-white">
                    {date.toLocaleTimeString()}
                  </td>
                  <td className="px-6 py-4 text-right text-yellow-500 font-bold">
                    {record.points} pts
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MinerTable;
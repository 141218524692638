// src/components/TopMiners/index.js
import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, Trophy, Medal } from "lucide-react";
import { API_URL } from "../../constants";

const TopMiners = () => {
  // localStorage'dan başlangıç durumunu al
  const [isOpen, setIsOpen] = useState(() => {
    const savedState = localStorage.getItem('topMinersOpen');
    // Eğer daha önce kaydedilmiş bir durum yoksa, varsayılan olarak açık
    return savedState === null ? true : savedState === 'true';
  });
  
  const [topMiners, setTopMiners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // isOpen durumu değiştiğinde localStorage'a kaydet
  useEffect(() => {
    localStorage.setItem('topMinersOpen', isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return; // Panel kapalıysa veri çekme

    const fetchTopMiners = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/top-miners`);
        console.log('Top miners response:', response);
        
        if (!response.ok) {
          throw new Error(`Error fetching top miners: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Top miners data:', data);
        
        setTopMiners(data);
        setError(null);
      } catch (error) {
        console.error("Error fetching top miners:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTopMiners();
    // Her 30 saniyede bir güncelle
    const interval = setInterval(fetchTopMiners, 30000);
    return () => clearInterval(interval);
  }, [isOpen]); // isOpen'a bağlı olarak çalış

  // ID'yi maskeleme fonksiyonu (ilk 4 ve son 4 karakteri göster)
  const maskId = (id) => {
    if (!id) return "Unknown";
    if (id.length <= 8) return id;
    return `${id.slice(0, 4)}...${id.slice(-4)}`;
  };

  // Panel tamamen kapalıysa hiçbir şey render etme
  if (!isOpen) {
    return (
      <button
        onClick={() => setIsOpen(true)}
        className="fixed left-0 top-1/3 z-50 h-10 px-2 bg-gray-900/95 backdrop-blur-lg border border-gray-800 rounded-r-lg flex items-center justify-center text-gray-400 hover:text-white transition-colors"
      >
        <ChevronRight className="w-5 h-5" />
      </button>
    );
  }

  return (
    <div className="fixed left-0 top-1/3 z-50">
      <div className="flex">
        {/* Ana Panel */}
        <div className="w-72 bg-gray-900/95 backdrop-blur-lg border-y border-r border-gray-800 rounded-r-xl overflow-hidden">
          {/* Başlık */}
          <div className="p-4 bg-gray-900 border-b border-gray-800">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Trophy className="w-5 h-5 text-yellow-500" />
                <h2 className="text-white font-semibold">Best Fractal Diggers</h2>
              </div>
              {loading && (
                <div className="w-4 h-4 border-2 border-gray-400 border-t-yellow-500 rounded-full animate-spin" />
              )}
            </div>
          </div>

          {/* Liste */}
          <div className="py-2">
            {error ? (
              <div className="px-4 py-2 text-red-500 text-sm">{error}</div>
            ) : topMiners.length === 0 && !loading ? (
              <div className="px-4 py-2 text-gray-400 text-sm">No miners found</div>
            ) : (
              topMiners.map((miner, index) => (
                <div
                  key={miner.id || index}
                  className="px-4 py-3 hover:bg-gray-800/50 transition-colors"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <div className="w-6 text-center">
                        {index === 0 ? (
                          <Medal className="w-5 h-5 text-yellow-500" />
                        ) : index === 1 ? (
                          <Medal className="w-5 h-5 text-gray-400" />
                        ) : index === 2 ? (
                          <Medal className="w-5 h-5 text-yellow-700" />
                        ) : (
                          <span className="text-gray-500">{index + 1}</span>
                        )}
                      </div>
                      <span className="text-sm font-mono text-gray-300">
                        {maskId(miner.id)}
                      </span>
                    </div>
                    <div className="text-right">
                      <span className="text-sm font-bold text-yellow-500">
                        {miner.points?.toLocaleString() || "0"} pts
                      </span>
                      {miner.referralCount > 0 && (
                        <div className="text-xs text-gray-400">
                          {miner.referralCount} refs
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Kapatma Butonu */}
        <button
          onClick={() => setIsOpen(false)}
          className="h-10 px-2 self-start mt-12 bg-gray-900/95 backdrop-blur-lg border border-gray-800 rounded-r-lg flex items-center justify-center text-gray-400 hover:text-white transition-colors"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default TopMiners;
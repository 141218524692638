// src/services/userService.js
import { API_URL } from "../constants";

const CARD_MULTIPLIERS = {
  '1': 0.15, // Bronze Pickaxe: +15%
  '2': 0.30, // Silver Pickaxe: +30%
  '3': 0.75, // Gold Pickaxe: +75%
  '4': 1.25, // Diamond Pickaxe: +125%
  '5': 2.00, // Fractal Pickaxe: +200%
  '6': 4.00, // Mystery Pickaxe: +400%
};

export const userService = {
  async getIpAddress() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error getting IP:", error);
      return "127.0.0.1";
    }
  },

  async createUser(ip, referralCode = "") {
    try {
      console.log("Creating user with:", { ip, referralCode });

      const response = await fetch(`${API_URL}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ip, referralCode }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Failed to create user");
      }

      const user = await response.json();
      console.log("User created:", user);
      return user;
    } catch (error) {
      console.error("Error in createUser:", error);
      throw error;
    }
  },

  async getUserById(id) {
    try {
      const response = await fetch(`${API_URL}/users/${id}`);

      if (!response.ok) {
        throw new Error(`Error getting user: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error in getUserById:", error);
      throw error;
    }
  },

  async getTopMiners() {
    try {
      const response = await fetch(`${API_URL}/top-miners`);
      if (!response.ok) {
        throw new Error(`Failed to fetch top miners: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Error fetching top miners:", error);
      throw error;
    }
  },

  async switchUserWithSecret(userId, secretCode) {
    try {
      console.log('Switching user:', { userId, secretCode });
      
      const response = await fetch(`${API_URL}/users/switch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, secretCode }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Error switching user: ${response.status}`);
      }
  
      const user = await response.json();
      localStorage.setItem('nftMinerId', user.id);
      return user;
    } catch (error) {
      console.error('Error in switchUser:', error);
      throw error;
    }
  },

  async addMiningPoints(userId, points) {
    try {
      const response = await fetch(`${API_URL}/mining/points`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, points }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `Error adding points: ${response.status}`
        );
      }

      return await response.json();
    } catch (error) {
      console.error("Error in addMiningPoints:", error);
      throw error;
    }
  },

  async unlockCard(userId, cardId, pointCost) {
    try {
      console.log('Unlocking card with params:', { userId, cardId, pointCost });

      const response = await fetch(`${API_URL}/users/unlock-card`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, cardId, pointCost }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `Error unlocking card: ${response.status}`
        );
      }

      return await response.json();
    } catch (error) {
      console.error("Error in unlockCard:", error);
      throw error;
    }
  },

  async updateBitcoinAddress(userId, btcAddress) {
    try {
      console.log('Updating BTC address:', { userId, btcAddress });
  
      const response = await fetch(`${API_URL}/users/update-btc`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, btcAddress }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Error updating Bitcoin address: ${response.status}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error in updateBitcoinAddress:', error);
      throw error;
    }
  },

  async getMerchantStatus(userId) {
    try {
      const response = await fetch(`${API_URL}/users/${userId}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `Error getting merchant status: ${response.status}`
        );
      }
      const user = await response.json();
      return {
        points: user.points || 0,
        dailyFlips: user.dailyFlips || 0,
        merchantHistory: user.merchantHistory || [],
      };
    } catch (error) {
      console.error("Error in getMerchantStatus:", error);
      throw error;
    }
  },

  async submitMerchantBet(userId, betAmount, won) {
    try {
      const response = await fetch(`${API_URL}/merchant/bet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          betAmount,
          won,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `Error submitting bet: ${response.status}`
        );
      }

      return await response.json();
    } catch (error) {
      console.error("Error in submitMerchantBet:", error);
      throw error;
    }
  },

  getPointsMultiplier(unlockedCards = []) {
    console.log('Calculating multiplier for cards:', unlockedCards);
    
    let totalMultiplier = 1;
    
    if (unlockedCards && unlockedCards.length > 0) {
      const maxBonus = Math.max(...unlockedCards.map(cardId => {
        const cardIdStr = cardId.toString();
        const multiplier = CARD_MULTIPLIERS[cardIdStr] || 0;
        console.log(`Card ${cardIdStr} multiplier:`, multiplier);
        return multiplier;
      }));
      
      console.log('Highest bonus:', maxBonus);
      totalMultiplier += maxBonus;
    }
    
    console.log('Final multiplier:', totalMultiplier);
    return totalMultiplier;
  },

  // Space Game Methods
  async getSpaceStatus(userId) {
    try {
      const response = await fetch(`${API_URL}/users/${userId}/space`);
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      return await response.json();
    } catch (error) {
      console.error('Error in getSpaceStatus:', error);
      throw error;
    }
  },
  
  async submitSpaceMission(userId, shipId, success, points, message) {
    try {
      const response = await fetch(`${API_URL}/users/${userId}/missions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ shipId, success, points, message }),
      });
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      return await response.json();
    } catch (error) {
      console.error('Error in submitSpaceMission:', error);
      throw error;
    }
  },
  async purchaseShip(userId, shipId, cost) {
    try {
      const response = await fetch(`${API_URL}/space/purchase-ship`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          shipId,
          cost
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Error purchasing ship: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("Error in purchaseShip:", error);
      throw error;
    }
  },

  async getSpaceStatus(userId) {
    try {
      const response = await fetch(`${API_URL}/space/status/${userId}`);
      if (!response.ok) {
        throw new Error(`Error getting space status: ${response.status}`);
      }
      const data = await response.json();
      return {
        ...data,
        dailyMissionLimit: data.ownedShips.length,
        remainingMissions: data.ownedShips.length - data.dailySpaceMissions
      };
    } catch (error) {
      console.error("Error in getSpaceStatus:", error);
      throw error;
    }
  },
  async submitSpaceMission(userId, shipId, success, points, message) {
    try {
      const response = await fetch(`${API_URL}/space/mission`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          shipId,
          success,
          points,
          message
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Error submitting mission: ${response.status}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error("Error in submitSpaceMission:", error);
      throw error;
    }
  },
};

export default userService;
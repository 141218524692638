// src/components/Mining/MiningProgress.js
import React from 'react';

const MiningProgress = ({ progress, miningCount, dailyLimit, points }) => {
  return (
    <div className="space-y-6">
      <div className="relative">
        <div className="h-2 bg-gray-800 rounded-full overflow-hidden">
          <div
            className="h-full bg-gradient-to-r from-yellow-500 to-yellow-600 transition-all duration-300"
            style={{ width: `${progress}%` }}
          />
        </div>
        {progress > 0 && (
          <div className="absolute inset-0 bg-yellow-500/20 rounded-full blur-sm animate-pulse" />
        )}
      </div>
      
      <div className="text-center space-y-3">
        <p className="text-gray-400">Mining Progress: {miningCount}/6</p>
        <p className="text-gray-400">Daily Mines: {dailyLimit}/2</p>
        <p className="text-yellow-500 text-xl font-bold">Points: {points}</p>
      </div>
    </div>
  );
};

export default MiningProgress;
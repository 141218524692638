import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LOADING_MESSAGES = [
  "Don't forget, you might find diamonds while mining! 💎",
  "Captain, stay focused! Space is full of mysteries 🚀",
  "Remember to check your mining tools before each session ⛏️",
  "The best miners never give up, they dig deeper! 💪",
  "Space missions are dangerous, but rewards are worth it 🌟",
  "Your pickaxe is your best friend in the mines 🔨",
  "Legends say some miners found ancient treasures... 🏆",
  "Keep your Bitcoin wallet ready for the rewards 🪙"
];

// Assets to preload
const ASSETS_TO_LOAD = [
  // Videos
  { type: 'video', path: '/videos/background.mp4', name: 'Background Video' },
  { type: 'video', path: '/videos/mining-animation.mp4', name: 'Mining Animation' },
  { type: 'video', path: '/videos/box1.mp4', name: 'Box Animation 1' },
  { type: 'video', path: '/videos/box2.mp4', name: 'Box Animation 2' },
  { type: 'video', path: '/space/1.mp4', name: 'Space Animation' },
  
  // Images
  { type: 'image', path: '/images/icons/fractal.png', name: 'Fractal Icon' },
  { type: 'image', path: '/images/icons/fractals.png', name: 'Fractals Icon' },
  { type: 'image', path: '/images/merchant/x2.png', name: 'Merchant Icon' },
  { type: 'image', path: '/cards/image1.jpg', name: 'Card 1' },
  { type: 'image', path: '/cards/image2.jpg', name: 'Card 2' },
  { type: 'image', path: '/cards/image3.jpg', name: 'Card 3' },
  { type: 'image', path: '/cards/image4.jpg', name: 'Card 4' },
  { type: 'image', path: '/cards/image5.jpg', name: 'Card 5' },
  { type: 'image', path: '/cards/image6.jpg', name: 'Card 6' },
  
  // Sound Effects
  { type: 'audio', path: '/sounds/mining-1.mp3', name: 'Mining Sound 1' },
  { type: 'audio', path: '/sounds/mining-2.mp3', name: 'Mining Sound 2' },
  { type: 'audio', path: '/sounds/mining-3.mp3', name: 'Mining Sound 3' },
  { type: 'audio', path: '/sounds/mining-4.mp3', name: 'Mining Sound 4' },
  { type: 'audio', path: '/sounds/mining-5.mp3', name: 'Mining Sound 5' },
  { type: 'audio', path: '/sounds/mining-6.mp3', name: 'Mining Sound 6' }
];

const Start = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [loadedAssets, setLoadedAssets] = useState(0);
  const [currentMessage, setCurrentMessage] = useState(LOADING_MESSAGES[0]);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [currentAsset, setCurrentAsset] = useState('');

  useEffect(() => {
    // Check if this is the first visit
    const hasVisited = localStorage.getItem('hasVisitedBefore');
    if (hasVisited) {
      setIsFirstVisit(false);
      navigate('/miners');
      return;
    }

    const loadAssets = async () => {
      let loaded = 0;

      const loadPromises = ASSETS_TO_LOAD.map(asset => {
        return new Promise((resolve) => {
          setCurrentAsset(asset.name);

          if (asset.type === 'image') {
            const img = new Image();
            img.src = asset.path;
            img.onload = () => {
              loaded++;
              setLoadedAssets(loaded);
              setProgress((loaded / ASSETS_TO_LOAD.length) * 100);
              resolve();
            };
            img.onerror = () => resolve(); // Continue loading even if an asset fails
          } else if (asset.type === 'video') {
            const video = document.createElement('video');
            video.src = asset.path;
            video.load();
            video.oncanplaythrough = () => {
              loaded++;
              setLoadedAssets(loaded);
              setProgress((loaded / ASSETS_TO_LOAD.length) * 100);
              resolve();
            };
            video.onerror = () => resolve();
          } else if (asset.type === 'audio') {
            const audio = new Audio();
            audio.src = asset.path;
            audio.load();
            audio.oncanplaythrough = () => {
              loaded++;
              setLoadedAssets(loaded);
              setProgress((loaded / ASSETS_TO_LOAD.length) * 100);
              resolve();
            };
            audio.onerror = () => resolve();
          }
        });
      });

      // Change messages periodically
      const messageInterval = setInterval(() => {
        setCurrentMessage(prev => {
          const currentIndex = LOADING_MESSAGES.indexOf(prev);
          const nextIndex = (currentIndex + 1) % LOADING_MESSAGES.length;
          return LOADING_MESSAGES[nextIndex];
        });
      }, 3000);

      // Wait for all assets to load
      await Promise.all(loadPromises);
      clearInterval(messageInterval);

      // Mark as visited and redirect
      localStorage.setItem('hasVisitedBefore', 'true');
      setTimeout(() => {
        navigate('/miners');
      }, 1000);
    };

    loadAssets();
  }, [navigate]);

  if (!isFirstVisit) return null;

  return (
    <div className="fixed inset-0 bg-background flex items-center justify-center">
      <div className="w-full max-w-2xl px-4">
        <div className="text-center mb-12">
          <img
            src="/images/icons/fractal.png"
            alt="Fractal"
            className="w-24 h-24 mx-auto mb-6"
          />
          <h1 className="text-3xl font-bold text-white mb-2">
            Welcome to Fractal Miners
          </h1>
          <p className="text-gray-400">
            Preparing your mining equipment...
          </p>
        </div>

        <div className="space-y-6">
          <div className="relative">
            <div className="h-3 bg-gray-800 rounded-full overflow-hidden">
              <div
                className="h-full bg-primary transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="absolute inset-0 bg-primary/20 rounded-full blur-sm animate-pulse" />
          </div>

          <div className="text-center space-y-2">
            <p className="text-primary font-medium">
              {loadedAssets} / {ASSETS_TO_LOAD.length} Assets Loaded
            </p>
            <p className="text-sm text-gray-500">
              Loading: {currentAsset}
            </p>
          </div>

          <div className="p-4 rounded-lg bg-gray-800/50 border border-gray-700">
            <p className="text-center text-gray-300 animate-pulse">
              {currentMessage}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Start;
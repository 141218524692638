import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import TopMiners from '../TopMiners';
import { Rocket, Lock, Star, Users, Package, Timer, Info, AlertTriangle, CheckCircle, History } from 'lucide-react';
import { userService } from '../../services/userService';
import ReferralModal from '../ReferralModal';

const SOUND_EFFECTS = {
  success: [
    '/ships/success/1.mp3',
    '/ships/success/2.mp3',
    '/ships/success/3.mp3',
    '/ships/success/4.mp3',
    '/ships/success/5.mp3'
  ],
  failed: [
    '/ships/failed/1.mp3',
    '/ships/failed/2.mp3',
    '/ships/failed/3.mp3',
    '/ships/failed/4.mp3',
    '/ships/failed/5.mp3'
  ]
};

const RESULT_IMAGES = {
  success: [
    '/ships/success/1.jpg',
    '/ships/success/2.jpg',
    '/ships/success/3.jpg',
    '/ships/success/4.jpg'
  ],
  failed: [
    '/ships/failed/1.jpg',
    '/ships/failed/2.jpg',
    '/ships/failed/3.jpg',
    '/ships/failed/4.jpg'
  ]
};

const SPACESHIP_TIERS = {
  1: {
    id: 1,
    name: 'Basic Explorer',
    points: 500,
    minReward: 100,
    maxReward: 500,
    color: 'text-blue-500',
    bgColor: 'bg-blue-500/10',
    borderColor: 'border-blue-500/20',
    image: '/ships/1.jpg',
    miningSpeed: 13,
    successRate: 70,
    cost: 1000
  },
  2: {
    id: 2,
    name: 'Advanced Explorer',
    points: 2500,
    minReward: 300,
    maxReward: 1500,
    color: 'text-purple-500',
    bgColor: 'bg-purple-500/10',
    borderColor: 'border-purple-500/20',
    image: '/ships/2.jpg',
    miningSpeed: 11,
    successRate: 80,
    cost: 5000
  }
};

const MiningMessages = [
  "Initiating mining sequence...",
  "Scanning for valuable minerals...",
  "Discovered some treasure chests!",
  "Mining in progress...",
  "Extracting rare materials...",
  "Found ancient space artifacts!",
  "Analyzing mineral composition...",
  "Processing valuable resources..."
];

// Success and failure messages
const successMessages = [
  "Your pilot skills saved the crew from a pirate attack and brought the minerals home!",
  "Successfully navigated through an asteroid field and secured valuable resources!",
  "Survived a close encounter with a black hole and escaped with precious cargo!",
  "Outmaneuvered rival mining ships and claimed the richest asteroid deposits!"
];

const failureMessages = [
  "Your ship was caught in an asteroid storm and couldn't make it...",
  "Engine failure in deep space led to a mission failure...",
  "Hostile alien encounter forced an emergency evacuation...",
  "Ship's core overheated during mining operations..."
];

const MissionHistory = ({ history }) => (
  <div className="rounded-xl border border-gray-800 bg-gray-900/50 p-6">
    <div className="flex items-center gap-2 mb-4">
      <History className="w-5 h-5 text-yellow-500" />
      <h3 className="text-lg font-bold text-white">Mission History</h3>
    </div>

    <div className="space-y-4">
      {history.map((entry, index) => (
        <div 
          key={index}
          className={`p-4 rounded-lg ${
            entry.success ? 'bg-green-500/10 border-green-500/20' : 'bg-red-500/10 border-red-500/20'
          } border`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              {entry.success ? (
                <CheckCircle className="w-5 h-5 text-green-500" />
              ) : (
                <AlertTriangle className="w-5 h-5 text-red-500" />
              )}
              <div>
                <p className={`font-medium ${entry.success ? 'text-green-500' : 'text-red-500'}`}>
                  {entry.success ? 'Successful Mission' : 'Failed Mission'}
                </p>
                <p className="text-sm text-gray-400">
                  Ship: {SPACESHIP_TIERS[entry.shipId].name}
                </p>
              </div>
            </div>
            <div className="text-right">
              <p className={`font-bold ${entry.success ? 'text-yellow-500' : 'text-red-500'}`}>
                {entry.success ? `+${entry.points}` : '-'} Points
              </p>
              <p className="text-sm text-gray-400">
                {new Date(entry.timestamp).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      ))}

      {history.length === 0 && (
        <div className="text-center text-gray-400 py-4">
          No missions completed yet
        </div>
      )}
    </div>
  </div>
);

const MissionResult = ({ success, points, message, onClaim, videoRef }) => {
  const [showText, setShowText] = useState(false);
  const [showPoints, setShowPoints] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [resultImage] = useState(getRandomItem(success ? RESULT_IMAGES.success : RESULT_IMAGES.failed));
  const audioRef = useRef(new Audio(getRandomItem(success ? SOUND_EFFECTS.success : SOUND_EFFECTS.failed)));
  
  useEffect(() => {
    // Pause the background video when mission result appears
    const backgroundVideo = document.querySelector('video');
    if (backgroundVideo) {
      backgroundVideo.pause();
    }

    audioRef.current.play().catch(console.error);
    
    const textTimer = setTimeout(() => setShowText(true), 500);
    const pointsTimer = setTimeout(() => setShowPoints(true), 1000);
    const buttonTimer = setTimeout(() => setShowButton(true), 1500);

    return () => {
      // Resume the background video when mission result disappears
      if (backgroundVideo) {
        backgroundVideo.play().catch(console.error);
      }
      
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      clearTimeout(textTimer);
      clearTimeout(pointsTimer);
      clearTimeout(buttonTimer);
    };
  }, []);

  const handleClaim = () => {
    // Resume video playback when claiming reward
    const backgroundVideo = document.querySelector('video');
    if (backgroundVideo) {
      backgroundVideo.play().catch(console.error);
    }
    
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    onClaim();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center px-4">
      <div className={`absolute inset-0 ${success ? 'bg-green-500/20' : 'bg-red-500/20'} backdrop-blur-sm`} />
      <div className="relative bg-gray-900/90 rounded-xl border border-gray-800 p-6 max-w-md w-full mx-4">
        <div className="text-center space-y-4">
          <div className="relative w-full aspect-video rounded-lg overflow-hidden">
            <img
              src={resultImage}
              alt={success ? 'Mission Success' : 'Mission Failed'}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/50">
              <h3 
                className={`text-2xl font-bold mb-2 transition-all duration-500 ${
                  showText ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'
                } ${success ? 'text-green-500' : 'text-red-500'}`}
              >
                {success ? 'Mission Successful!' : 'Mission Failed!'}
              </h3>
              <p 
                className={`text-sm text-gray-200 max-w-sm px-4 transition-all duration-500 ${
                  showText ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
                }`}
              >
                {message}
              </p>
            </div>
          </div>

          {success && (
            <div 
              className={`text-2xl font-bold text-yellow-500 transition-all duration-500 ${
                showPoints ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
              }`}
            >
              +{points} Points
            </div>
          )}

          <button
            onClick={handleClaim}
            className={`w-full py-3 rounded-lg bg-yellow-500 text-white font-semibold hover:bg-yellow-600 transition-all duration-500 ${
              showButton ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
            }`}
          >
            {success ? 'Claim Reward' : 'Try Again'}
          </button>
        </div>
      </div>
    </div>
  );
};

const PurchaseConfirmation = ({ ship, onConfirm, onCancel }) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center px-4">
    <div className="absolute inset-0 bg-black/75 backdrop-blur-sm" onClick={onCancel} />
    <div className="relative bg-gray-900 rounded-xl border border-gray-800 p-8 max-w-md w-full">
      <div className="text-center space-y-6">
        <div className={`w-16 h-16 mx-auto rounded-full ${ship.bgColor} flex items-center justify-center`}>
          <Rocket className={`w-8 h-8 ${ship.color}`} />
        </div>
        
        <div className="space-y-2">
          <h3 className="text-xl font-bold text-white">Purchase {ship.name}?</h3>
          <p className="text-gray-400">This will cost {ship.cost.toLocaleString()} points</p>
          
          <div className={`mt-4 p-4 rounded-lg ${ship.bgColor}`}>
            <div className="space-y-2">
              <p className={ship.color}>• Success Rate: {ship.successRate}%</p>
              <p className={ship.color}>• Mining Speed: {ship.miningSpeed}s</p>
              <p className={ship.color}>• Rewards: {ship.minReward} - {ship.maxReward} Points</p>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <button
            onClick={onCancel}
            className="flex-1 py-3 rounded-lg bg-gray-800 text-gray-400 font-semibold hover:bg-gray-700 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="flex-1 py-3 rounded-lg bg-yellow-500 text-white font-semibold hover:bg-yellow-600 transition-colors"
          >
            Purchase
          </button>
        </div>
      </div>
    </div>
  </div>
);

// Helper function to get random item from array
const getRandomItem = (array) => array[Math.floor(Math.random() * array.length)];

const Space = () => {
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState(null);
  const [points, setPoints] = useState(0);
  const [selectedShip, setSelectedShip] = useState(null);
  const [missionResult, setMissionResult] = useState(null);
  const [isMining, setIsMining] = useState(false);
  const [progress, setProgress] = useState(0);
  const [dailyMissions, setDailyMissions] = useState(0);
  const [showReferralModal, setShowReferralModal] = useState(!localStorage.getItem('nftMinerId'));
  const [ownedShips, setOwnedShips] = useState([]);
  const [showPurchaseConfirmation, setShowPurchaseConfirmation] = useState(null);
  const [missionHistory, setMissionHistory] = useState([]);
  const [miningMessage, setMiningMessage] = useState('');

  const videoRef = useRef(null);
  const messageIntervalRef = useRef(null);
  const handleJoinWithReferral = async (referralCode) => {
    try {
      const ip = await userService.getIpAddress();
      const newUser = await userService.createUser(ip, referralCode);
      if (newUser) {
        setUserId(newUser.id);
        setUserData(newUser);
        localStorage.setItem('nftMinerId', newUser.id);
        setShowReferralModal(false);
      }
    } catch (error) {
      console.error('Error joining with referral:', error);
    }
  };
  
  const handleSkipReferral = async () => {
    try {
      const ip = await userService.getIpAddress();
      const newUser = await userService.createUser(ip);
      if (newUser) {
        setUserId(newUser.id);
        setUserData(newUser);
        localStorage.setItem('nftMinerId', newUser.id);
        setShowReferralModal(false);
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };
  const handlePurchaseShip = (ship) => {
    if (points >= ship.cost) {
      setShowPurchaseConfirmation(ship);
    } else {
      alert("Not enough points to purchase this ship!");
    }
  };

  const confirmPurchase = async () => {
    try {
      const ship = showPurchaseConfirmation;
      const updatedUser = await userService.purchaseShip(userId, ship.id, ship.cost);
      
      setPoints(updatedUser.points);
      setOwnedShips(updatedUser.ownedShips);
      setSelectedShip(ship);
      setShowPurchaseConfirmation(null);
    } catch (error) {
      console.error('Error purchasing ship:', error);
      alert(error.message);
    }
  };
  const startMission = async () => {
    // İlk kontrolleri yapalım
    if (!selectedShip || isMining) return;
  
    // Sahip olunan gemi sayısı kadar görev hakkı
    const dailyLimit = ownedShips.length;
    
    // Eğer limit aşılmışsa görev başlatma
    if (dailyMissions >= dailyLimit) {
      setMiningMessage('Daily mission limit reached. Come back tomorrow!');
      return;
    }
  
    setIsMining(true);
    setProgress(0);
  
    // Video kontrolü
    if (videoRef.current) {
      videoRef.current.muted = false;
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch(console.error);
    }
  
    const duration = selectedShip.miningSpeed * 1000;
    const startTime = Date.now();
  
    const animate = () => {
      const elapsed = Date.now() - startTime;
      const currentProgress = Math.min((elapsed / duration) * 100, 100);
      setProgress(currentProgress);
  
      if (currentProgress < 100) {
        requestAnimationFrame(animate);
      } else {
        completeMission();
      }
    };
  
    requestAnimationFrame(animate);
  };


  const completeMission = () => {
    const success = Math.random() * 100 < selectedShip.successRate;
    
    const newMission = {
      timestamp: new Date(),
      shipId: selectedShip.id,
      success: success,
      points: 0,
      message: success ? getRandomItem(successMessages) : getRandomItem(failureMessages)
    };
  
    if (success) {
      newMission.points = Math.floor(
        Math.random() * (selectedShip.maxReward - selectedShip.minReward + 1) + 
        selectedShip.minReward
      );
    }
  
    // Mission result'ı ayarla
    setMissionResult({
      success: success,
      points: newMission.points,
      message: newMission.message
    });
  
    // History'yi hemen güncelle
    setMissionHistory(prev => [newMission, ...prev]);
    
    setIsMining(false);
    setProgress(0);
  };

  const handleClaim = async () => {
    if (missionResult) {
      try {
        // Server'a gönder
        const updatedUser = await userService.submitSpaceMission(
          userId,
          selectedShip.id,
          missionResult.success,
          missionResult.points,
          missionResult.message
        );
  
        // State'leri güncelle
        setPoints(updatedUser.points);
        setDailyMissions(updatedUser.dailySpaceMissions);
        setMissionHistory(updatedUser.spaceHistory || []);
  
        if (!missionResult.success) {
          // Başarısız durumda gemiyi kaldır
          setOwnedShips(updatedUser.ownedShips);
          setSelectedShip(null);
          setMiningMessage('Mission failed! Ship lost. Try again tomorrow.');
        }
  
      } catch (error) {
        console.error('Error submitting mission result:', error);
      }
    }
    setMissionResult(null);
  };

 // useEffect içinde ilk yükleme kontrolünü güncelle
 useEffect(() => {
  const loadUser = async () => {
    try {
      const storedId = localStorage.getItem('nftMinerId');
      if (storedId) {
        const user = await userService.getUserById(storedId);
        if (user) {
          setUserId(user.id);
          setUserData(user);
          setPoints(user.points || 0);
          setMissionHistory(user.spaceHistory || []); // Tüm history
          setOwnedShips(user.ownedShips || []);

          // Bugünkü görevleri kontrol et
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const todayMissions = user.spaceHistory.filter(mission => {
            const missionDate = new Date(mission.date);
            missionDate.setHours(0, 0, 0, 0);
            return missionDate.getTime() === today.getTime();
          });

          setDailyMissions(todayMissions.length);

          // Son görev başarısız mı kontrol et
          const lastMission = user.spaceHistory[0];
          if (lastMission && !lastMission.success) {
            setMiningMessage('Mission failed! Try again tomorrow.');
          }
        }
      }
    } catch (error) {
      console.error('Error loading user:', error);
    }
  };

  loadUser();

  return () => {
    if (messageIntervalRef.current) {
      clearInterval(messageIntervalRef.current);
    }
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };
}, []);

  return (
    <div className="min-h-screen bg-gray-900 relative overflow-hidden">
      {showReferralModal && (
        <ReferralModal
          onJoin={handleJoinWithReferral}
          onSkip={handleSkipReferral}
        />
      )}
      <div className="fixed inset-0 bg-gradient-to-b from-gray-900 to-black" />
      <div className="relative z-10">
        <Header userId={userId} userData={userData} />
    
        <TopMiners />

        <main className="container mx-auto px-4 pt-28 pb-12">
          <div className="max-w-4xl mx-auto space-y-8">
            {/* Stats Section */}
            <div className="rounded-xl border border-gray-800 bg-gray-900/50 p-6">
              <div className="space-y-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Star className="w-5 h-5 text-yellow-500" />
                    <span className="text-white font-bold">{points.toLocaleString()} Points</span>
                  </div>
                  <div className="text-sm text-gray-400">
  Daily Missions: {dailyMissions}/{ownedShips.length}
</div>
                </div>

                {selectedShip && (
                  <div className={`p-3 rounded-lg ${selectedShip.bgColor}`}>
                    <div className="flex items-center gap-3">
                      <Rocket className={`w-5 h-5 ${selectedShip.color}`} />
                      <span className={`${selectedShip.color} font-medium`}>
                        Mining with {selectedShip.name}
                      </span>
                    </div>
                  </div>
                )}

                {/* Mining View */}
                <div className="relative aspect-video rounded-lg overflow-hidden bg-gray-800">
                  <video
                    ref={videoRef}
                    className="absolute inset-0 w-full h-full object-cover"
                    src="/space/1.mp4"
                    loop
                    muted
                    playsInline
                  />
                  
                  <div className="absolute inset-0 flex flex-col items-center justify-center">
                    {miningMessage && (
                      <div className="bg-black/60 backdrop-blur-sm px-6 py-3 rounded-lg">
                        <p className="text-yellow-500 text-lg animate-pulse">
                          {miningMessage}
                        </p>
                      </div>
                    )}
                  </div>
                  
                  <div className="absolute inset-x-0 bottom-0 p-4">
                    <div className="relative">
                      <div className="h-3 bg-gray-800/80 rounded-full overflow-hidden">
                        <div
                          className="h-full bg-yellow-500 transition-all duration-300"
                          style={{ width: `${progress}%` }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Action Button */}
                <button
  onClick={startMission}
  disabled={isMining || dailyMissions >= ownedShips.length || !selectedShip}
  className={`w-full py-4 rounded-lg font-bold transition-all ${
    isMining || dailyMissions >= ownedShips.length || !selectedShip
      ? 'bg-gray-800 text-gray-500 cursor-not-allowed'
      : 'bg-yellow-500 text-white hover:bg-yellow-600 hover:scale-[1.02]'
  }`}
>
  {isMining 
    ? 'Mission in Progress...' 
    : dailyMissions >= ownedShips.length 
    ? 'Daily Missions Completed' 
    : !selectedShip
    ? 'Select a Ship to Start'
    : 'Launch Mission'}
</button>

                {dailyMissions >= 2 && (
                  <p className="text-center text-sm text-gray-400">
                    Daily mission limit reached. Come back tomorrow!
                  </p>
                )}
              </div>
            </div>

            {/* Ships Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {Object.values(SPACESHIP_TIERS).map((ship) => (
  <div
    key={ship.id}
    className={`rounded-xl border ${
      ownedShips.includes(ship.id) 
        ? ship.borderColor 
        : points >= ship.cost  // Puanı yeterliyse
        ? 'border-green-500/50 animate-pulse' // Yeşil yanma efekti
        : 'border-gray-800'
    } bg-gray-900/50 p-6`}
  >
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <div className={`p-2 rounded-lg ${ship.bgColor}`}>
                          <Rocket className={`w-5 h-5 ${ship.color}`} />
                        </div>
                        <div>
                          <h3 className="font-bold text-white">{ship.name}</h3>
                          <p className={`text-sm ${ship.color}`}>
                            Success Rate: {ship.successRate}%
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="space-y-2">
                      <div className="flex justify-between text-sm text-gray-400">
                        <span>Mining Speed:</span>
                        <span>{ship.miningSpeed}s</span>
                      </div>
                      <div className="flex justify-between text-sm text-gray-400">
                        <span>Reward Range:</span>
                        <span>{ship.minReward} - {ship.maxReward} Points</span>
                      </div>
                    </div>

                    {ownedShips.includes(ship.id) ? (
                      <button
                        onClick={() => setSelectedShip(ship)}
                        className={`w-full py-3 rounded-lg ${
                          selectedShip?.id === ship.id
                            ? 'bg-yellow-500 text-white'
                            : `${ship.bgColor} ${ship.color}`
                        } font-semibold transition-colors`}
                      >
                        {selectedShip?.id === ship.id ? 'Selected' : 'Select Ship'}
                      </button>
                    ) : (
                      <button
                      onClick={() => handlePurchaseShip(ship)}
                      className={`w-full py-3 rounded-lg ${
                        points >= ship.cost 
                          ? 'bg-green-500/20 text-green-400 hover:bg-green-500/30' // Yeşil buton
                          : 'bg-gray-800 text-gray-400'
                      } font-semibold transition-colors`}
                    >
                      Purchase for {ship.cost.toLocaleString()} Points
                    </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Mission History */}
            <MissionHistory history={missionHistory} />
          </div>
        </main>
      </div>

      {missionResult && (
        <MissionResult
          success={missionResult.success}
          points={missionResult.points}
          message={missionResult.message}
          onClaim={handleClaim}
        />
      )}

      {showPurchaseConfirmation && (
        <PurchaseConfirmation
          ship={showPurchaseConfirmation}
          onConfirm={confirmPurchase}
          onCancel={() => setShowPurchaseConfirmation(null)}
        />
      )}
    </div>
  );
};

export default Space;
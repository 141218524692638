// src/components/NotificationModal/index.js
import React from 'react';
import { Hammer, ArrowUp } from 'lucide-react';

const PICKAXE_DETAILS = {
  1: { name: 'Bronze Pickaxe', boost: '+15%', color: 'text-amber-600', bgColor: 'bg-amber-500/10' },
  2: { name: 'Silver Pickaxe', boost: '+30%', color: 'text-gray-400', bgColor: 'bg-gray-500/10' },
  3: { name: 'Gold Pickaxe', boost: '+75%', color: 'text-yellow-500', bgColor: 'bg-yellow-500/10' },
  4: { name: 'Diamond Pickaxe', boost: '+125%', color: 'text-blue-400', bgColor: 'bg-blue-500/10' },
  5: { name: 'Fractal Pickaxe', boost: '+200%', color: 'text-purple-500', bgColor: 'bg-purple-500/10' },
  6: { name: 'Mystery Pickaxe', boost: '+400%', color: 'text-red-500', bgColor: 'bg-red-500/10' }
};

const NotificationModal = ({ points, onClose, cardUnlocked = null }) => (
  <div className="fixed inset-0 flex items-center justify-center z-50 px-4">
    <div className="absolute inset-0 bg-black/60 backdrop-blur-sm" onClick={onClose} />
    <div className="relative bg-gray-900 rounded-xl border border-gray-800 p-8 max-w-md w-full">
      <div className="absolute top-4 right-4">
        <button
          onClick={onClose}
          className="text-gray-400 hover:text-white transition-colors"
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="text-center">
        {cardUnlocked ? (
          <>
            <div className={`w-16 h-16 rounded-full ${PICKAXE_DETAILS[cardUnlocked.id].bgColor} flex items-center justify-center mx-auto mb-4`}>
              <Hammer className={`w-8 h-8 ${PICKAXE_DETAILS[cardUnlocked.id].color}`} />
            </div>
            <h3 className={`text-2xl font-bold ${PICKAXE_DETAILS[cardUnlocked.id].color} mb-2`}>
              {PICKAXE_DETAILS[cardUnlocked.id].name} Unlocked!
            </h3>
            <div className="space-y-4">
              <p className="text-gray-400">
                You've successfully unlocked a new pickaxe with enhanced mining capabilities!
              </p>
              
              <div className={`p-4 rounded-lg ${PICKAXE_DETAILS[cardUnlocked.id].bgColor} border border-opacity-20`}>
                <div className="flex items-center justify-center gap-2">
                  <ArrowUp className={`w-5 h-5 ${PICKAXE_DETAILS[cardUnlocked.id].color}`} />
                  <span className={`font-bold ${PICKAXE_DETAILS[cardUnlocked.id].color}`}>
                    {PICKAXE_DETAILS[cardUnlocked.id].boost} Mining Boost
                  </span>
                </div>
              </div>

              <div className="p-4 rounded-lg bg-yellow-500/10 border border-yellow-500/20">
                <p className="text-yellow-500 font-bold">-{cardUnlocked.points.toLocaleString()} Points Used</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-16 h-16 rounded-full bg-yellow-500/10 flex items-center justify-center mx-auto mb-4">
              <svg className="w-8 h-8 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
              </svg>
            </div>
            <h3 className="text-xl font-bold text-white mb-2">Congratulations!</h3>
            <p className="text-gray-400 mb-4">
              You've successfully completed the mining cycle and earned
            </p>
            <p className="text-2xl font-bold text-yellow-500 mb-6">
              {points.toLocaleString()} Points
            </p>
          </>
        )}

        <button
          onClick={onClose}
          className="w-full py-3 rounded-lg bg-yellow-500 text-white font-semibold hover:bg-yellow-600 transition-colors"
        >
          {cardUnlocked ? 'Awesome!' : 'Continue Mining'}
        </button>
      </div>
    </div>
  </div>
);

export default NotificationModal;
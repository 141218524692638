// src/components/MiningApp/index.js
import React, { useState, useEffect, useRef } from "react";
import { Hammer, ArrowUp, Lock, LogOut, Wallet } from "lucide-react";
import Header from "../Header";
import MinerTable from "../Market/MinerTable";
import MiningProgress from "../Mining/MiningProgress";
import SwitchAccountForm from "../SwitchAccountForm";
import BitcoinAddressForm from "../BitcoinAddressForm";
import { userService } from "../../services/userService";
import TopMiners from "../TopMiners";
import ReferralModal from "../ReferralModal";
import NotificationModal from "../NotificationModal";
import PickaxeStatus from "../PickaxeStatus";
import { useWallet, truncateAddress } from "../../hooks";

const MINING_SOUNDS = [
  "/sounds/mining-1.mp3",
  "/sounds/mining-2.mp3",
  "/sounds/mining-3.mp3",
  "/sounds/mining-4.mp3",
  "/sounds/mining-5.mp3",
  "/sounds/mining-6.mp3",
];

const NFTBanner = () => {
  const { connected, address, connect, disconnect } = useWallet();

  const onConnect = () => {
    if (connected) return disconnect();
    connect();
  };

  return (
    <div className="mb-8 rounded-xl border border-yellow-500/20 bg-yellow-500/5 p-6">
      <div className="text-center mb-6">
        <h2 className="text-2xl font-bold text-yellow-500 mb-2">
          Fractal Miners NFT Collection
        </h2>
        <p className="text-gray-400">Limited Edition of 3333 NFTs</p>
      </div>

      <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
        <button
          onClick={onConnect}
          className="px-6 py-3 bg-gray-800 rounded-lg text-gray-300 hover:bg-gray-700 transition-colors flex items-center gap-2"
        >
          {connected ? (
            <>
              <span className="font-semibold">{truncateAddress(address)}</span>
              <LogOut className="w-5 h-5" />
            </>
          ) : (
            <>
              <Wallet className="w-5 h-5" />
              <span>Connect Wallet</span>
            </>
          )}
        </button>

        <button className="px-6 py-3 bg-yellow-500 rounded-lg text-white hover:bg-yellow-600 transition-colors flex items-center gap-2">
          <svg
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
            />
          </svg>
          Mint (27.12.2024)
        </button>
      </div>
    </div>
  );
};

const InfoBox = () => (
  <div className="mb-8 rounded-xl border border-yellow-500/20 bg-yellow-500/5 p-6">
    <div className="flex items-center gap-3 mb-4">
      <img src="/images/icons/fractal.png" alt="Fractal" className="w-8 h-8" />
      <h2 className="text-xl font-bold text-yellow-500">
        Welcome to Fractal Miners
      </h2>
    </div>

    <div className="space-y-4 text-gray-300">
      <p className="leading-relaxed">
        Start mining to earn points and unlock exclusive NFTs on the Fractal
        Network! As the first project on the Fractal Chain, we offer a unique
        mining experience where you can use your points to mint and upgrade your
        NFTs.
      </p>

      <div className="flex flex-wrap gap-6 mt-4">
        <a
          href="https://twitter.com/fractalminerz"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 text-yellow-500 hover:text-yellow-400 transition-colors"
        >
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
            <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
          </svg>
          @fractalminerz
        </a>

        <a
          href="https://fractalminers.com"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 text-yellow-500 hover:text-yellow-400 transition-colors"
        >
          <svg
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
            />
          </svg>
          fractalminers.com
        </a>
      </div>
    </div>
  </div>
);
const MiningCard = ({
  card,
  userPoints,
  isUnlocked,
  onUnlock,
  videoSrc,
  imageSrc,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(console.error);
    }
  }, []);

  return (
    <div className="relative h-[420px] overflow-hidden rounded-xl border border-gray-800">
      <video
        ref={videoRef}
        className="absolute inset-0 h-full w-full object-cover"
        src={videoSrc}
        loop
        muted
        playsInline
      />

      <div className="absolute inset-0 bg-black/60 backdrop-blur-sm" />

      <div className="absolute inset-x-0 top-6 flex justify-center">
        <img
          src={imageSrc}
          alt={card.name}
          className="h-100 w-100 rounded-lg object-cover"
        />
      </div>

      <div className="absolute inset-x-0 bottom-0 p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-bold text-white">{card.name}</h3>
          {userPoints >= card.points && !isUnlocked ? (
            <button
              onClick={() => onUnlock(card)}
              className="p-2 rounded-lg bg-yellow-500/20 text-yellow-500 hover:bg-yellow-500/30 transition-colors"
            >
              <Hammer className="w-5 h-5" />
            </button>
          ) : isUnlocked ? (
            <div className="p-2 rounded-lg bg-green-500/20 text-green-500">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 5.707 8.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l7-7a1 1 0 000-1.414z" />
              </svg>
            </div>
          ) : (
            <div className="p-2 rounded-lg bg-gray-800/50 text-gray-500">
              <Lock className="w-5 h-5" />
            </div>
          )}
        </div>

        <div className="space-y-2">
          <div className="flex justify-between items-center text-sm">
            <span className="text-gray-400">Required Points:</span>
            <span className="text-yellow-500 font-bold">
              {card.points.toLocaleString()}
            </span>
          </div>

          <div className="h-2 bg-gray-800 rounded-full overflow-hidden">
            <div
              className="h-full bg-yellow-500 transition-all duration-300"
              style={{
                width: `${Math.min((userPoints / card.points) * 100, 100)}%`,
              }}
            />
          </div>

          <div className="text-right text-xs text-gray-400">
            {Math.floor((userPoints / card.points) * 100)}% Completed
          </div>
        </div>
      </div>
    </div>
  );
};

const MiningCards = ({ userPoints, unlockedCards = [], onUnlock }) => {
  const cards = [
    {
      id: 1,
      name: "Bronze Pickaxe",
      points: 100,
      videoSrc: "/videos/box2.mp4",
      imageSrc: "/cards/image1.jpg",
    },
    {
      id: 2,
      name: "Silver Pickaxe",
      points: 1000,
      videoSrc: "/videos/box2.mp4",
      imageSrc: "/cards/image2.jpg",
    },
    {
      id: 3,
      name: "Gold Pickaxe",
      points: 10000,
      videoSrc: "/videos/box2.mp4",
      imageSrc: "/cards/image3.jpg",
    },
    {
      id: 4,
      name: "Diamond Pickaxe",
      points: 50000,
      videoSrc: "/videos/box1.mp4",
      imageSrc: "/cards/image4.jpg",
    },
    {
      id: 5,
      name: "Fractal Pickaxe",
      points: 100000,
      videoSrc: "/videos/box1.mp4",
      imageSrc: "/cards/image5.jpg",
    },
    {
      id: 6,
      name: "Mystery Pickaxe",
      points: 1000000,
      videoSrc: "/videos/box1.mp4",
      imageSrc: "/cards/image6.jpg",
    },
  ];

  const firstRow = cards.slice(0, 3);
  const secondRow = cards.slice(3);

  return (
    <div className="mt-12">
      <h2 className="text-2xl font-bold text-white mb-6">Mining Cards</h2>
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {firstRow.map((card) => (
            <MiningCard
              key={card.id}
              card={card}
              userPoints={userPoints}
              isUnlocked={unlockedCards.includes(card.id)}
              onUnlock={onUnlock}
              videoSrc={card.videoSrc}
              imageSrc={card.imageSrc}
            />
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {secondRow.map((card) => (
            <MiningCard
              key={card.id}
              card={card}
              userPoints={userPoints}
              isUnlocked={unlockedCards.includes(card.id)}
              onUnlock={onUnlock}
              videoSrc={card.videoSrc}
              imageSrc={card.imageSrc}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const MiningApp = () => {
  const [userId, setUserId] = useState("");
  const [miningCount, setMiningCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [dailyLimit, setDailyLimit] = useState(0);
  const [points, setPoints] = useState(0);
  const [miningHistory, setMiningHistory] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [earnedPoints, setEarnedPoints] = useState(0);
  const [unlockedCard, setUnlockedCard] = useState(null);
  const [unlockedCards, setUnlockedCards] = useState([]);
  const [userData, setUserData] = useState(null);
  const [showReferralModal, setShowReferralModal] = useState(
    !localStorage.getItem("nftMinerId")
  );

  const videoRef = useRef(null);
  const bgVideoRef = useRef(null);
  const audioRefs = useRef(MINING_SOUNDS.map(() => new Audio()));

  const handleJoinWithReferral = async (referralCode) => {
    try {
      const ip = await userService.getIpAddress();
      const newUser = await userService.createUser(ip, referralCode);
      if (newUser) {
        setUserId(newUser.id);
        setUserData(newUser);
        setPoints(newUser.points || 0);
        setDailyLimit(newUser.dailyMines || 0);
        setMiningHistory(newUser.miningHistory || []);
        setUnlockedCards(newUser.unlockedCards || []);
        localStorage.setItem("nftMinerId", newUser.id);
        setShowReferralModal(false);
      }
    } catch (error) {
      console.error("Error joining with referral:", error);
    }
  };

  const handleSkipReferral = async () => {
    try {
      const ip = await userService.getIpAddress();
      const newUser = await userService.createUser(ip);
      if (newUser) {
        console.log("New user created:", newUser); // Debug için log
        setUserId(newUser.id);
        setUserData(newUser);
        setPoints(newUser.points || 0);
        setDailyLimit(newUser.dailyMines || 0);
        setMiningHistory(newUser.miningHistory || []);
        setUnlockedCards([]);
        localStorage.setItem("nftMinerId", newUser.id);
        setShowReferralModal(false);
      }
    } catch (error) {
      console.error("Error creating user:", error);
      alert("Failed to create user. Please try again.");
    }
  };

  useEffect(() => {
    const loadUser = async () => {
      try {
        if (!localStorage.getItem("nftMinerId")) {
          setShowReferralModal(true);
          return;
        }

        const storedId = localStorage.getItem("nftMinerId");
        console.log("Stored ID:", storedId);

        if (storedId) {
          const user = await userService.getUserById(storedId);
          if (user) {
            console.log("User loaded:", user);
            setUserId(user.id);
            setPoints(user.points || 0);
            setDailyLimit(user.dailyMines || 0);
            setMiningHistory(user.miningHistory || []);
            setUnlockedCards(user.unlockedCards || []);
            setUserData(user);
          } else {
            console.log("No user found with stored ID, creating new user");
            const ip = await userService.getIpAddress();
            const newUser = await userService.createUser(ip);
            if (newUser) {
              console.log("New user created:", newUser);
              localStorage.setItem("nftMinerId", newUser.id);
              setUserId(newUser.id);
              setPoints(newUser.points || 0);
              setDailyLimit(newUser.dailyMines || 0);
              setMiningHistory(newUser.miningHistory || []);
              setUnlockedCards([]);
              setUserData(newUser);
            }
          }
        }
      } catch (error) {
        console.error("Error loading/creating user:", error);
      }
    };

    loadUser();

    if (bgVideoRef.current) {
      bgVideoRef.current.play().catch(console.error);
    }

    MINING_SOUNDS.forEach((sound, index) => {
      audioRefs.current[index].src = sound;
      audioRefs.current[index].load();
    });

    return () => {
      audioRefs.current.forEach((audio) => {
        audio.pause();
        audio.currentTime = 0;
      });
      if (bgVideoRef.current) {
        bgVideoRef.current.pause();
      }
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, []);

  const handleSwitchAccount = async (inputId, secretCode) => {
    try {
      const user = await userService.switchUserWithSecret(inputId, secretCode);
      if (user) {
        setUserId(user.id);
        setPoints(user.points || 0);
        setDailyLimit(user.dailyMines || 0);
        setMiningHistory(user.miningHistory || []);
        setUnlockedCards(user.unlockedCards || []);
        setMiningCount(0);
        setProgress(0);
        setIsLoading(false);
        setUserData(user);
      }
    } catch (error) {
      alert(error.message || "Failed to switch account");
    }
  };

  const handleUnlockCard = async (card) => {
    if (points >= card.points && !unlockedCards.includes(card.id)) {
      try {
        const updatedUser = await userService.unlockCard(
          userId,
          card.id,
          card.points
        );
        if (updatedUser) {
          setPoints(updatedUser.points);
          setUnlockedCards(updatedUser.unlockedCards);
          setUnlockedCard(card);
          setShowNotification(true);
        }
      } catch (error) {
        console.error("Error unlocking card:", error);
        alert(error.message);
      }
    }
  };

  const playRandomMiningSound = () => {
    try {
      audioRefs.current.forEach((audio) => {
        audio.pause();
        audio.currentTime = 0;
      });

      const randomIndex = Math.floor(Math.random() * MINING_SOUNDS.length);
      const audio = audioRefs.current[randomIndex];
      audio.volume = 0.7;
      audio.currentTime = 0;

      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.error("Error playing sound:", error);
        });
      }
    } catch (error) {
      console.error("Error playing mining sound:", error);
    }
  };

  const handleMining = async () => {
    if (isLoading || dailyLimit >= 2 || miningCount >= 6) return;

    setIsLoading(true);
    setProgress(0);

    playRandomMiningSound();
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch(console.error);
    }

    const startTime = Date.now();
    const duration = 5000;
    const animate = () => {
      const elapsed = Date.now() - startTime;
      const currentProgress = Math.min((elapsed / duration) * 100, 100);
      setProgress(currentProgress);

      if (currentProgress < 100) {
        requestAnimationFrame(animate);
      }
    };
    requestAnimationFrame(animate);

    setTimeout(async () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }

      setProgress(0);
      setMiningCount((prev) => prev + 1);

      if (miningCount === 5) {
        try {
          // Log active cards and their effects
          console.log("Current unlocked cards:", unlockedCards);

          const multiplier = userService.getPointsMultiplier(unlockedCards);
          console.log("Calculated multiplier:", multiplier);

          const basePoints = Math.floor(Math.random() * 51) + 50;
          console.log("Base points generated:", basePoints);

          const newPoints = Math.floor(basePoints * multiplier);
          console.log(
            "Final points calculation:",
            basePoints,
            "*",
            multiplier,
            "=",
            newPoints
          );

          const updatedUser = await userService.addMiningPoints(
            userId,
            newPoints
          );
          if (updatedUser) {
            setPoints(updatedUser.points);
            setDailyLimit(updatedUser.dailyMines);
            setMiningHistory(updatedUser.miningHistory);
            setMiningCount(0);
            setEarnedPoints(newPoints);
            setUnlockedCard(null);
            setShowNotification(true);
          }
        } catch (error) {
          console.error("Error completing mining:", error);
          alert(error.message);
        }
      }

      setIsLoading(false);
    }, 5000);
  };

  return (
    <div className="min-h-screen bg-gray-900 relative overflow-hidden">
      {showReferralModal && (
        <ReferralModal
          onJoin={handleJoinWithReferral}
          onSkip={handleSkipReferral}
        />
      )}

      <div className="fixed inset-0 z-0">
        <video
          ref={bgVideoRef}
          className="absolute top-0 left-0 w-full h-full object-cover opacity-30"
          src="/videos/background.mp4"
          loop
          muted
          playsInline
          style={{
            filter: "blur(8px) brightness(0.4)",
          }}
        />
      </div>

      <div className="relative z-10">
        <Header
          userId={userId}
          userData={userData}
          setUserId={setUserId} // Yeni prop
          setUserData={setUserData} // Yeni prop
        />
        <TopMiners />

        <main className="container mx-auto px-4 pt-28 pb-12">
          <NFTBanner />
          <InfoBox />
          <SwitchAccountForm onSubmit={handleSwitchAccount} />
          <BitcoinAddressForm
            initialBtcAddress={userData?.btcAddress}
            onSubmit={async (btcAddress) => {
              try {
                const updatedUser = await userService.updateBitcoinAddress(
                  userId,
                  btcAddress
                );
                setUserData(updatedUser);
                alert("Bitcoin address updated successfully!");
              } catch (error) {
                alert(error.message || "Failed to update Bitcoin address");
              }
            }}
          />

          <section className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-1">
              <div className="rounded-xl border border-gray-800 bg-gray-900/50 overflow-hidden relative">
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent z-10" />
                <video
                  ref={videoRef}
                  className="w-full aspect-video object-cover"
                  src="/videos/mining-animation.mp4"
                  loop
                  muted
                  playsInline
                />
              </div>

              {unlockedCards.length > 0 && (
                <div className="mt-4">
                  <PickaxeStatus unlockedCards={unlockedCards} />
                </div>
              )}
            </div>

            <div className="lg:col-span-2">
              <div className="rounded-xl border border-gray-800 bg-gray-900/50 p-6 backdrop-blur-sm">
                <MiningProgress
                  progress={progress}
                  miningCount={miningCount}
                  dailyLimit={dailyLimit}
                  points={points}
                />

                <button
                  onClick={handleMining}
                  disabled={isLoading || dailyLimit >= 2 || miningCount >= 6}
                  className={`w-full mt-6 py-4 rounded-lg font-bold transition-all
                    ${
                      isLoading || dailyLimit >= 2 || miningCount >= 6
                        ? "bg-gray-800 text-gray-500 cursor-not-allowed"
                        : "bg-yellow-500 text-white hover:bg-yellow-600 hover:-translate-y-0.5"
                    }`}
                >
                  {isLoading
                    ? "Mining..."
                    : dailyLimit >= 2
                    ? "Daily Limit Reached"
                    : miningCount >= 6
                    ? "Mining Complete"
                    : "Start Mining"}
                </button>

                {dailyLimit >= 2 && (
                  <p className="mt-4 text-center text-sm text-gray-400">
                    Daily mining limit reached. Come back tomorrow!
                  </p>
                )}

                {miningCount >= 6 && dailyLimit < 2 && (
                  <p className="mt-4 text-center text-sm text-gray-400">
                    Mining cycle complete! Click one more time to claim your
                    rewards.
                  </p>
                )}
              </div>
            </div>
          </section>

          <MiningCards
            userPoints={points}
            unlockedCards={unlockedCards}
            onUnlock={handleUnlockCard}
          />

          <section className="mt-12">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-2xl font-bold text-white">Mining History</h2>
            </div>

            <MinerTable history={miningHistory} userId={userId} />
          </section>
        </main>
      </div>

      {showNotification && (
        <NotificationModal
          points={earnedPoints}
          cardUnlocked={unlockedCard}
          onClose={() => {
            setShowNotification(false);
            setUnlockedCard(null);
          }}
        />
      )}
    </div>
  );
};

export default MiningApp;

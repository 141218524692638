import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MiningApp from "./components/MiningApp";
import Airdrops from "./components/Airdrops";
import Merchant from "./components/Merchant";
import Trade from "./components/Trade";
import Space from "./components/Space";
import Start from "./components/Start";
import { MultiAccountWarning, VersionNotification } from "./components/Notifications";

const App = () => {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-background">
        <Routes>
          {/* Landing/Loading page */}
          <Route path="/" element={<Start />} />

          {/* Main application routes */}
          <Route path="/miners" element={<MiningApp />} />
          <Route path="/airdrops" element={<Airdrops />} />
          <Route path="/merchant" element={<Merchant />} />
          <Route path="/trade" element={<Trade />} />
          <Route path="/journey" element={<Space />} />
        </Routes>
        <MultiAccountWarning />
        <VersionNotification />
      </div>
    </BrowserRouter>
  );
};

export default App;